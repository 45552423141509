import CardWithRoles from "components/card/CardWithRoles";
import accessControlImage from "./icons/public-api-access-control.svg";

export default function AccessControlCard() {
  return (
    <CardWithRoles
      name="API - Access Control"
      image={accessControlImage}
      description="Manage and monitor API Keys and control access to solutions and web services."
      targetRoles={["Project leader", "Sales"]}
      link={`/api/access-control`}
      testid="public-api-access-control"
    />
  );
}
