import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { actionBarId } from "./ActionBar";

type ActionBarContentProps = {
  children: React.ReactNode;
};

export default function ActionBarContent({ children }: ActionBarContentProps) {
  const contentContainer = useRef(document.createElement("div"));

  useEffect(() => {
    const actionBar = document.getElementById(actionBarId);
    if (!actionBar)
      throw new Error("Action bar must be rendered to use ActionBarContent");

    const container = contentContainer.current;

    // use parent styling
    actionBar.classList.forEach(className => {
      container.classList.add(className);
    });

    actionBar.appendChild(container);
    return () => {
      actionBar.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, contentContainer.current);
}
