import { Tooltip, Typography } from "@mui/material";
import Link from "components/base/Link";
import {
  RestrictedWorkspaceRealm,
  WorkspacePermissions
} from "features/access-management";

/** Displays a link that allows to enter the workspace detail if the user has the required permissions nothing otherwise.  */
export default function WorkspaceLink({
  workspaceId,
  workspaceName
}: {
  workspaceId: string;
  workspaceName: string;
}) {
  return (
    <RestrictedWorkspaceRealm
      requiredPermissions={[WorkspacePermissions.ReadWorkspaces]}
      silent
    >
      <Link to={`/workspaces/${workspaceId}`}>
        <Tooltip title="Click to see the workspace capacity status and scaling policies">
          <Typography data-testid="capacity-settings" color="primary">
            {workspaceName}
          </Typography>
        </Tooltip>
      </Link>
    </RestrictedWorkspaceRealm>
  );
}
