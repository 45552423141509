import { AddCircle } from "@mui/icons-material";
import { Grid, LinearProgress } from "@mui/material";
import LinkIconButton from "components/base/LinkIconButton";
import { ErrorBox } from "components/error";
import {
  RestrictedSolutionRealm,
  SolutionPermission
} from "features/access-management";
import { ActionBarContent } from "features/shell";
import { useSolutionKey } from "features/solution";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useSearchQueryParam } from "utils/url";
import ProjectCard from "./ProjectCard";
import ProjectStateFilter from "./ProjectStateFilter";
import { useProjectsDataWithPolling } from "./hooks/projectsHooks";

export default function Projects() {
  const solutionKey = useSolutionKey();
  const {
    data,
    loading,
    error: projectsError
  } = useProjectsDataWithPolling(solutionKey);
  const [projectState, setProjectState] = useSearchQueryParam("projectState");
  const projects = useMemo(() => {
    if (!data) return [];
    let result = data.solutions.solution?.projects?.projects;
    if (!isEmpty(projectState)) {
      return result?.filter(project => {
        return project.state === projectState;
      });
    } else return result;
  }, [data, projectState]);
  if (loading) return <LinearProgress />;

  if (projectsError)
    return (
      <ErrorBox
        title="An error occurred while loading the projects"
        apolloError={projectsError}
      />
    );

  return (
    <RestrictedSolutionRealm
      requiredPermissions={[SolutionPermission.ReadProjects]}
    >
      <ActionBarContent>
        <RestrictedSolutionRealm
          requiredPermissions={[SolutionPermission.ChangeProjects]}
          silent
        >
          <LinkIconButton
            to={`/solutions/${solutionKey}/projects/create`}
            title="Add project"
            testId="add-project"
            icon={AddCircle}
          />
        </RestrictedSolutionRealm>
        <ProjectStateFilter onStateFilterChanged={setProjectState} />
      </ActionBarContent>
      <Grid container justifyContent="center" spacing={4}>
        {projects?.map(project => {
          const { id } = project;
          return <ProjectCard key={id} project={project} />;
        })}
      </Grid>
    </RestrictedSolutionRealm>
  );
}
