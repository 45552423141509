import { gql, useQuery } from "@apollo/client";
import { ScsVersions } from "./schema/ScsVersions";

const scsVersionsList = gql`
  query ScsVersions {
    workspaces {
      workspaces {
        id
        name
      }
    }
  }
`;

export const useScsVersions = (solutionKey: string) =>
  useQuery<ScsVersions>(scsVersionsList);
