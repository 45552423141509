import { AddCircle, GridView, TableRows } from "@mui/icons-material";

import LinkIconButton from "components/base/LinkIconButton";
import {
  RestrictedSolutionRealm,
  SolutionPermission
} from "features/access-management";
import { ActionBarContent } from "features/shell";
import { useSolutionKey } from "features/solution";
import { useSearchQueryParam } from "utils/url";
import UseCasesTabularView from "./UseCasesTabularView";
import UseCasesTileView from "./UseCasesTileView";
import ViewModeToggleButton from "./ViewModeToggleButton";

enum ViewMode {
  Tile = "tile",
  Tabular = "tabular"
}

export default function UseCases() {
  const solutionKey = useSolutionKey();
  const [currentViewMode, setCurrentViewMode] = useSearchQueryParam(
    "viewMode",
    ViewMode.Tile
  );
  const isTileViewMode = currentViewMode === ViewMode.Tile;
  return (
    <>
      <ActionBarContent>
        <RestrictedSolutionRealm
          requiredPermissions={[SolutionPermission.ChangeUseCases]}
          silent
        >
          <LinkIconButton
            to={`/solutions/${solutionKey}/create-use-case`}
            title="Add web service"
            testId="add-web-service"
            icon={AddCircle}
          />
        </RestrictedSolutionRealm>
        {isTileViewMode ? (
          <ViewModeToggleButton
            title="Switch to tabular view"
            testId="navigate-to-use-cases-tabular"
            handleClick={() => setCurrentViewMode("tabular")}
            icon={TableRows}
          />
        ) : (
          <ViewModeToggleButton
            title="Switch to tile view"
            testId="navigate-to-use-cases-tile"
            handleClick={() => setCurrentViewMode("tile")}
            icon={GridView}
          />
        )}
      </ActionBarContent>
      {isTileViewMode ? <UseCasesTileView /> : <UseCasesTabularView />}
    </>
  );
}
