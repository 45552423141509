import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MemberAvatar, { INameProvider } from "./MemberAvatar";

const useStyles = makeStyles(() => ({
  primary: { fontSize: 14 },
  secondary: {
    fontSize: 11
  },
  flex: { display: "flex", alignItems: "center", height: "100%" },
  flexColumn: { display: "flex", flexDirection: "column", marginLeft: 10 }
}));

interface IUserNamesProvider {
  data: INameProvider & { name: string };
}

export default function UserName({ data: member }: IUserNamesProvider) {
  const classes = useStyles();
  const hasName = member.firstName !== null && member.lastName !== null;
  return member ? (
    <div className={classes.flex}>
      <MemberAvatar member={member} />
      <div className={classes.flexColumn}>
        {hasName ? (
          <Typography
            className={classes.primary}
          >{`${member.firstName} ${member.lastName}`}</Typography>
        ) : (
          <Typography className={classes.primary}>{member.name}</Typography>
        )}
        {hasName ? (
          <Typography className={classes.secondary}>{member.name}</Typography>
        ) : null}
      </div>
    </div>
  ) : null;
}
