import { Error } from "@mui/icons-material";
import { Button, Divider, Card as MaterialCard } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    card: {
      width: "100%"
    },
    header: { display: "felx", flexDirection: "column" },
    errorIcon: {
      color: theme.palette.secondary.main,
      margin: theme.spacing(2)
    },
    title: {
      color: "#68777B",
      fontWeight: 400,
      fontSize: 18,
      textAlign: "center"
    },
    divider: { margin: theme.spacing(1, 1, 0, 1) },
    errorBoxButton: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
      border: "none",
      margin: theme.spacing(1),
      "&:hover": {
        border: "none",
        backgroundColor: theme.palette.primary.main
      }
    }
  })
);

interface CardProps {
  onClose?: (value: boolean) => void;
  copy?: () => void;
  children: React.ReactNode;
  title?: string;
  closable?: boolean;
  withActions?: boolean;
}
export default function Card({
  onClose,
  copy,
  children,
  title = "An error occurred",
  closable = false,
  withActions = true
}: CardProps) {
  const classNames = useStyles({});

  return (
    <div className={classNames.root}>
      <MaterialCard className={classNames.card}>
        <CardHeader
          className={classNames.header}
          classes={{ root: classNames.header, title: classNames.title }}
          avatar={<Error className={classNames.errorIcon} fontSize="large" />}
          title={title}
        />
        <CardContent>
          {children}
          {withActions && <Divider className={classNames.divider} />}
        </CardContent>
        {withActions ? (
          <CardActions>
            <>
              {closable && (
                <Button
                  className={classNames.errorBoxButton}
                  variant="outlined"
                  onClick={event => {
                    onClose && onClose(false);
                    event.preventDefault();
                  }}
                >
                  CLOSE
                </Button>
              )}
              <Button
                className={classNames.errorBoxButton}
                data-testid="copy-errors"
                variant="outlined"
                onClick={e => {
                  e.preventDefault();
                  copy && copy();
                }}
              >
                COPY
              </Button>
            </>
          </CardActions>
        ) : null}
      </MaterialCard>
    </div>
  );
}
