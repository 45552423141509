import { gql, useQuery } from "@apollo/client";
import { Grid, LinearProgress } from "@mui/material";
import { ErrorBox } from "components/error";
import {
  RestrictedSolutionRealm,
  SolutionPermission
} from "features/access-management";

import SpaceCard from "./SpaceCard";
import { useSolutionKey } from "./hooks/useSolutionKeyHook";
import {
  SolutionFeatures,
  SolutionFeaturesVariables
} from "./schema/SolutionFeatures";
import usageMetricsImage from "./solutionViewIcons/usage-metrics.svg";
import useCaseDesignerImage from "./solutionViewIcons/use-case-designer.svg";
import useCasesImage from "./solutionViewIcons/use-cases.svg";
import membersImage from "./solutionViewIcons/users.svg";

const querySolutionFeatures = gql`
  query SolutionFeatures($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        features {
          projects
          useCases
        }
      }
    }
  }
`;

export default function SolutionView() {
  const solutionKey = useSolutionKey();
  const { data, loading, error } = useQuery<
    SolutionFeatures,
    SolutionFeaturesVariables
  >(querySolutionFeatures, {
    variables: { solutionKey }
  });

  const features = data?.solutions?.solution?.features;

  if (loading) return <LinearProgress />;
  if (error) {
    return <ErrorBox apolloError={error} />;
  }
  return (
    <RestrictedSolutionRealm
      requiredPermissions={[SolutionPermission.ReadSolutionDetail]}
    >
      <Grid container spacing={4}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <SpaceCard
                name="Projects"
                requiredPermissions={[SolutionPermission.ReadProjects]}
                image={useCaseDesignerImage}
                description="Design web services with graphical programming inside the supply chain suite. Use uploaded data to make calculations and analyze the results."
                targetRoles={["SCS modeler", "customer"]}
                link={`/solutions/${solutionKey}/projects`}
                testid="projects-button"
                enabled={features?.projects}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <SpaceCard
                name="Web services"
                requiredPermissions={[SolutionPermission.ReadUseCases]}
                image={useCasesImage}
                description="Use uploaded data to make calculations and analyze the results."
                targetRoles={["customer"]}
                link={`/solutions/${solutionKey}/use-cases`}
                testid="use-cases-button"
                enabled={features?.useCases}
              />
            </Grid>
            <Grid item>
              <SpaceCard
                name="Usage metrics"
                image={usageMetricsImage}
                description="Monitor transactions per web service call and export transaction data to analyze transactions per customer API key."
                targetRoles={["Admin"]}
                link={`/solutions/${solutionKey}/usage-metrics`}
                testid="usage-metrics-button"
                requiredPermissions={[SolutionPermission.ReadTransactions]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <SpaceCard
                name="Members"
                requiredPermissions={[SolutionPermission.ReadSolutionMembers]}
                image={membersImage}
                description="Manage solution members and member permissions."
                targetRoles={["Project leader"]}
                link={`/solutions/${solutionKey}/members`}
                testid="members-button"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RestrictedSolutionRealm>
  );
}
