import { gql, useQuery } from "@apollo/client";
import { ErrorOutline } from "@mui/icons-material";
import {
  CalculationName as CalculationNameQuery,
  CalculationNameVariables
} from "./schema/CalculationName";

const queryCalculationName = gql`
  query CalculationName(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $calculationId: ID!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            calculation(calculationId: $calculationId) {
              inputData {
                detail {
                  metaData {
                    displayName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function CalculationName({
  solutionKey,
  useCaseKey,
  useCaseVersion = null,
  calculationId
}: CalculationNameVariables) {
  const { data, error } = useQuery<
    CalculationNameQuery,
    CalculationNameVariables
  >(queryCalculationName, {
    variables: { solutionKey, useCaseKey, useCaseVersion, calculationId }
  });
  const useCase = data?.solutions.solution?.useCases?.useCase;
  const calculationName =
    data?.solutions.solution?.useCases?.useCase?.calculation?.inputData.detail
      ?.metaData.displayName;
  return !useCase || error ? (
    <ErrorOutline color="secondary" />
  ) : (
    <div>{calculationName || calculationId}</div>
  );
}
