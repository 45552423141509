import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import { useSolutionKey } from "features/solution";
import CalculationNotExist from "./CalculationNotExist";
import CalculationResultTable from "./CalculationResultTable";
import { extractCalculationData } from "./calculationResultUtils";
import {
  useCalculationOutputData,
  useQueryCalculationSchema
} from "./hooks/calculationResultHooks";

const useStyles = makeStyles(theme => ({
  darkBackgroundColor: {
    backgroundColor: theme.palette.primary.dark
  },
  margin: {
    marginBottom: theme.spacing(1)
  }
}));

interface CalculationResultOutputTableProps {
  useCaseKey: string;
  useCaseVersion: string;
  calculationId: string;
  tableId: string;
}
const CalculationResultOutputTable = ({
  useCaseKey,
  useCaseVersion,
  calculationId,
  tableId: name
}: CalculationResultOutputTableProps) => {
  const { darkBackgroundColor, margin } = useStyles();
  const solutionKey = useSolutionKey();
  const { loadOutputData } = useCalculationOutputData();
  const { data, error, loading } = useQueryCalculationSchema({
    solutionKey,
    useCaseKey,
    useCaseVersion,
    calculationId
  });

  const calculation =
    data?.solutions.solution?.useCases?.useCases?.[0]?.calculation;

  if (loading) return <LinearProgress className={margin} />;
  if (error) return <ErrorBox apolloError={error} />;
  if (!calculation) {
    return <CalculationNotExist />;
  }
  const { outputColumnDefs } = extractCalculationData(calculation);
  return (
    <CalculationResultTable
      columnDefs={outputColumnDefs}
      name={name}
      useCaseKey={useCaseKey}
      useCaseVersion={useCaseVersion}
      calculationId={calculationId}
      loadData={loadOutputData}
      backgroundColor={darkBackgroundColor}
    />
  );
};
export default CalculationResultOutputTable;
