import { ApolloError } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import DataGridView from "components/grid/DataGridView";
import React from "react";
import { UseCaseTransactions } from "./hooks/useTransactionsHook";

type TransactionsGridProps = {
  transactions: UseCaseTransactions[];
  isLoading: boolean;
  toolbarItemRender?: () => React.ReactNode;
  errors: ApolloError[];
  withToolbar: boolean;
  isLimitationHintVisible: boolean;
  transactionsLimit: number;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    gap: 5
  },
  gridRoot: {
    "&.dx-widget .dx-datagrid .dx-datagrid-header-panel": {
      backgroundColor: "#F5F7F7"
    },
    "&.dx-widget .dx-datagrid .dx-toolbar": {
      backgroundColor: "#F5F7F7"
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td":
      {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        fontSize: 12,
        color: "#1E1E1E",
        padding: 0,
        paddingLeft: theme.spacing(1),
        height: 24,
        borderRight: "solid 1px #aaa",
        borderLeft: "solid 1px #aaa",
        gap: 4,
        "&:hover": { color: "red" }
      },
    "&.dx-widget .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content":
      { color: "#6e6e6e" },

    "&.dx-widget .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover:not(.dx-command-select):not(.dx-command-expand):not( .dx-editor-cell ):not(.dx-command-edit):not(.dx-datagrid-group-space)":
      {
        backgroundColor: "#E6E6E6"
      },
    "&.dx-widget .dx-datagrid .dx-row-lines > td": {
      border: "none",
      padding: "4px 8px",
      height: 40,
      color: "#464646",
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left !important",
      "&:hover": { border: "solid 2px #969696" }
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-columns-separator": {
      backgroundColor: "#464646",
      width: 1
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-columns-separator-transparent": {
      backgroundColor: "transparent"
    },
    "&.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-row": {
      "&:hover": { backgroundColor: "#EEF6F8" }
    }
  },
  warningBar: {
    height: 32,
    backgroundColor: "#FFF7CD",
    border: "solid 1px #FFC328",
    padding: "3px 5px",
    color: "#B64C24",
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 3,
    display: "flex",
    alignItems: "center"
  },
  errorBar: {
    backgroundColor: "#FBEEED",
    border: "1px solid #FFADAD",
    padding: "3px 5px",
    color: "#DC0000",
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    gap: 5
  }
}));

const TransactionsGrid: React.FC<TransactionsGridProps> = ({
  transactions,
  isLoading,
  errors,
  toolbarItemRender,
  withToolbar = true,
  isLimitationHintVisible,
  transactionsLimit
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isLimitationHintVisible && (
        <div className={classes.warningBar}>
          There might be more data. The maximum number of transactions{" "}
          {transactionsLimit} has been returned. Please change the filter to
          drill down on the data.
        </div>
      )}
      {errors.length > 0 && (
        <div className={classes.errorBar}>
          {errors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </div>
      )}
      <DataGridView<UseCaseTransactions>
        rowData={transactions}
        isLoading={isLoading}
        showFilterRow={false}
        allowSelection={false}
        height={"100%"}
        columns={[
          {
            colDef: {
              dataField: "useCaseKey",
              caption: "Service",
              allowSorting: true,
              allowFiltering: false
            }
          },
          {
            colDef: {
              dataField: "useCaseVersion",
              caption: "Version",
              allowSorting: true,
              allowFiltering: false
            }
          },
          {
            colDef: {
              dataField: "apiKey.name",
              caption: "Customer (API Key)",
              allowSorting: true,
              allowFiltering: false
            }
          },
          {
            colDef: {
              dataField: "tableName",
              caption: "Table",
              allowSorting: false,
              allowFiltering: false
            }
          },
          {
            colDef: {
              dataField: "transactionCount",
              caption: "Transactions",
              allowSorting: true,
              allowFiltering: false
            }
          },
          {
            colDef: {
              dataField: "requestId",
              caption: "Request ID",
              allowSorting: true,
              allowFiltering: false
            }
          },
          {
            colDef: {
              dataField: "timestamp",
              caption: "Timestamp",
              dataType: "datetime",
              allowSorting: true,
              allowFiltering: false
            }
          },
          {
            colDef: {
              dataField: "calculationStatus",
              caption: "Calculation Status",
              allowSorting: true,
              allowFiltering: false
            }
          }
        ]}
        withToolbar={withToolbar}
        toolbarItemRender={toolbarItemRender}
        gridClasses={{ root: classes.gridRoot }}
      />
    </div>
  );
};

export default TransactionsGrid;
