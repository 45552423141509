import { Typography } from "@mui/material";
import { InvalidRouteBase } from "features/shell";

export default function NoUseCaseFound() {
  return (
    <InvalidRouteBase>
      <Typography>
        Your link may be broken or the web service may have been removed.
      </Typography>
    </InvalidRouteBase>
  );
}
