import { useMutation } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import FullSize from "components/base/FullSize";
import { ErrorBox } from "components/error";
import ConfirmGridActionDialog from "components/grid/ConfirmGridActionDialog";
import DataGridView from "components/grid/DataGridView";
import { useSolutionKey } from "features/solution";
import {
  DeleteUseCase,
  DeleteUseCaseVariables,
  mutationDeleteUseCase
} from "features/use-case";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import UseCaseToolsPanel from "./UseCaseToolsPanel";
import { UseCaseManagementOverview_solutions_solution_useCases_useCases } from "./hooks/schema/UseCaseManagementOverview";
import {
  queryUseCaseManagementList,
  useUseCaseManagementOverview
} from "./hooks/useCaseManagementHooks";

export default function UseCasesTabularView() {
  const solutionKey = useSolutionKey();
  const [selectedRow, setSelectedRow] =
    useState<UseCaseManagementOverview_solutions_solution_useCases_useCases>();
  const [openRemoveUserDialog, setOpenRemoveUserDialog] = React.useState(false);

  const handleCloseRemoveUserDialog = () => {
    setOpenRemoveUserDialog(false);
  };

  const history = useHistory();

  const {
    data,
    loading: loadingUseCases,
    error
  } = useUseCaseManagementOverview(solutionKey);
  const [lazyUseDeleteUseCase] = useMutation<
    DeleteUseCase,
    DeleteUseCaseVariables
  >(mutationDeleteUseCase, {
    refetchQueries: [
      { query: queryUseCaseManagementList, variables: { solutionKey } }
    ]
  });

  const useCases = useMemo(() => {
    if (!data) return [];
    let result = data.solutions.solution?.useCases?.useCases;
    if (!result) return [];
    return result;
  }, [data]);
  if (error) {
    return <ErrorBox apolloError={error} />;
  }
  return (
    <>
      {openRemoveUserDialog ? (
        <ConfirmGridActionDialog
          message={`You are about to remove ${selectedRow?.detail.name}`}
          onConfirm={async () => {
            if (selectedRow)
              await lazyUseDeleteUseCase({
                variables: { solutionKey, useCaseKey: selectedRow.detail.key }
              });
          }}
          open={openRemoveUserDialog}
          onClose={handleCloseRemoveUserDialog}
        />
      ) : null}
      <FullSize id="use-case-management-table">
        <DataGridView
          rowData={useCases || []}
          columns={[
            {
              colDef: { dataField: "detail.key", caption: "Key", groupIndex: 0 }
            },
            {
              colDef: { dataField: "detail.name", caption: "Name", width: 300 }
            },
            {
              colDef: {
                dataField: "detail.version",
                caption: "Version",
                width: 75
              }
            },
            {
              colDef: { dataField: "detail.tags", caption: "Tags", width: 300 }
            },
            {
              colDef: {
                dataField: "detail.description",
                caption: "Description",
                width: 300
              }
            }
          ]}
          allowGrouping
          onContentReady={params => {
            const grid = params.component;
            grid.selectRowsByIndexes([1]);
          }}
          withToolbar
          toolbarItemRender={() => (
            <UseCaseToolsPanel
              history={history}
              selectedRow={selectedRow}
              setOpenRemoveUserDialog={setOpenRemoveUserDialog}
            />
          )}
          onSelectionChanged={row => setSelectedRow(row.selectedRowsData[0])}
        />
        {loadingUseCases && <LinearProgress />}
      </FullSize>
    </>
  );
}
