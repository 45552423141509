import { LinearProgress, Typography } from "@mui/material";
import { SelectBox } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { NestedKeyOf } from "utils/typescript";
import { useStyles } from "./FiltersComponentsStyle";

type SelectBoxProps<TValue> = {
  items: TValue[];
  displayExpr: NestedKeyOf<TValue>;
  valueExpr: NestedKeyOf<TValue>;
  value?: string;
  onValueChanged: (e: ValueChangedEvent) => void;
  placeholder?: string;
  label: string;
  loading?: boolean;
};

export default function SelectComboBox<TValue>(props: SelectBoxProps<TValue>) {
  const classNames = useStyles();
  const {
    items,
    displayExpr,
    valueExpr,
    value,
    onValueChanged,
    placeholder,
    label,
    loading
  } = props;
  return (
    <div className={classNames.selectBoxWrapper}>
      <div className={classNames.indicatorWrapper}>
        {loading && <LinearProgress />}
      </div>
      <Typography className={classNames.label}>{label}</Typography>
      <div>
        <SelectBox
          items={items}
          displayExpr={displayExpr}
          valueExpr={valueExpr}
          value={value}
          onValueChanged={onValueChanged}
          className={classNames.selectBox}
          dropDownOptions={{
            wrapperAttr: { class: classNames.dropdownlist }
          }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
