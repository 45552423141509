import { Typography } from "@mui/material";
import DateRangeBox from "devextreme-react/date-range-box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import React from "react";
import { Period } from "./helpers/updateDateRange";
import SelectComboBox from "./SelectComboBox";
import { useStyles } from "./UsageMetricsStyle";

type TimePeriods = {
  id: Period;
  name: string;
};
type TimePeriodSelectionProps = {
  selectedPeriod?: Period;
  handlePeriodChange: (e: ValueChangedEvent) => void;
  startDate?: Date;
  endDate?: Date;
  handleDateRangeChange: (value: Array<any | number | string>) => void;
};
const TimePeriodSelection: React.FC<TimePeriodSelectionProps> = ({
  handleDateRangeChange,
  handlePeriodChange,

  selectedPeriod,
  startDate,
  endDate
}) => {
  const classNames = useStyles();

  const timePeriods: TimePeriods[] = [
    { id: "all_time", name: "All Time" },
    { id: "last_week", name: "Last Week" },
    { id: "last_month", name: "Last Month" },
    { id: "last_6_months", name: "Last 6 Months" },
    { id: "last_year", name: "Last Year" },
    { id: "custom", name: "Custom" }
  ];

  return (
    <>
      <SelectComboBox<TimePeriods>
        items={timePeriods}
        displayExpr="name"
        valueExpr="id"
        value={selectedPeriod}
        onValueChanged={handlePeriodChange}
        placeholder="Select a period of time"
        label="Period of time"
      />
      {selectedPeriod === "custom" && (
        <div>
          <Typography className={classNames.label}>Date Range</Typography>
          <DateRangeBox
            startDate={startDate ?? undefined}
            endDate={endDate ?? undefined}
            displayFormat="dd-MMM-yyyy"
            dropDownOptions={{
              wrapperAttr: {
                class: classNames.dateRangeDropDown
              }
            }}
            useMaskBehavior={true}
            onValueChange={handleDateRangeChange}
            className={classNames.dateRange}
            startDateLabel=""
            endDateLabel=""
            multiView={false}
            endDatePlaceholder="DD-MMM-YYYY"
            startDatePlaceholder="DD-MMM-YYYY"
          />
        </div>
      )}
    </>
  );
};

export default TimePeriodSelection;
