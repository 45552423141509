import FullSize from "components/base/FullSize";
import DataGridView from "components/grid/DataGridView";
import {
  ContentReadyEvent,
  RowDblClickEvent,
  SelectionChangedEvent
} from "devextreme/ui/data_grid";
import {
  BytesCellRenderer,
  DateCellRenderer,
  UserNameCellRenderer
} from "../../components/grid/CellRenderers";
import { UseCaseData_solutions_solution_useCases_useCase_data } from "./topics/calculate/schema/UseCaseData";

type UseCaseDataSelectionGridProps = {
  onSelectionChanged?: (e: SelectionChangedEvent) => void;
  onRowDoubleClicked?: (e: RowDblClickEvent) => void;
  dataId?: string;
  inApiTester?: boolean;
  rowData: UseCaseData_solutions_solution_useCases_useCase_data[];
  toolbar?: boolean;
  toolbarItemRender?: () => React.ReactNode;
  toolbarItemLocation?: "center" | "after" | "before";
};

function UseCaseDataSelectionGrid({
  onSelectionChanged,
  onRowDoubleClicked,
  dataId,
  inApiTester,
  rowData,
  toolbar,
  toolbarItemRender,
  toolbarItemLocation = "after"
}: UseCaseDataSelectionGridProps) {
  const contentReadyHandler = (params: ContentReadyEvent) => {
    if (dataId) {
      const items = params.component.getDataSource().items();

      const rowWithSameDataId = items.find(
        item => item.detail.key.split("/").slice(-1)[0] === dataId
      );
      rowWithSameDataId &&
        params.component.selectRows([rowWithSameDataId], true);
    } else {
      if (!inApiTester) {
        params.component.selectRowsByIndexes([0]);
      }
    }
  };

  return (
    <FullSize id="data-grid">
      <DataGridView<UseCaseData_solutions_solution_useCases_useCase_data>
        columns={[
          {
            colDef: {
              dataField: "detail.metaData.displayName",
              caption: "Name"
            }
          },
          {
            colDef: {
              caption: "Uploaded",
              dataField: "detail.lastModified",
              dataType: "date"
            },
            render: p =>
              DateCellRenderer({ value: p.data.detail?.lastModified })
          },
          {
            colDef: {
              caption: "User",
              dataField: "detail.metaData.creator.name"
            },
            render: p => {
              const creator = p.data.detail?.metaData.creator;
              return UserNameCellRenderer({ user: creator });
            }
          },
          {
            colDef: {
              caption: "Size",
              dataField: "detail.size",
              alignment: "left"
            },
            render: p => BytesCellRenderer({ value: p.data.detail?.size })
          }
        ]}
        rowData={rowData}
        onRowDblClick={onRowDoubleClicked}
        withToolbar={toolbar}
        onContentReady={contentReadyHandler}
        toolbarItemRender={toolbarItemRender}
        toolbarItemLocation={toolbarItemLocation}
        onSelectionChanged={onSelectionChanged}
      />
    </FullSize>
  );
}

export default UseCaseDataSelectionGrid;
