import { gql, useQuery } from "@apollo/client";
import {
  StreamingSessions,
  StreamingSessionsVariables
} from "./schema/StreamingSessions";

export const sessionsList = gql`
  query StreamingSessions($solutionKey: Key!, $projectId: ID!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          project(projectId: $projectId) {
            id
            streamingSessions {
              user {
                name
                firstName
                lastName
              }
              isConnected
              startTime
            }
          }
        }
      }
    }
  }
`;

export const useSessionsDataWithPolling = (
  variables: StreamingSessionsVariables
) =>
  useQuery<StreamingSessions, StreamingSessionsVariables>(sessionsList, {
    variables,
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000
  });
