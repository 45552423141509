import { AppBar, Container, LinearProgress } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { History } from "history";
import { observer } from "mobx-react-lite";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react-core";
import { RestrictedRealmContextProvider } from "features/access-management";
import { SolutionKeyProvider } from "features/solution";
import { Title } from "pages/Routes";
import { RouteModule } from "pages/RoutesTyping";
import InvalidRoute from "./InvalidRoute";
import Logo from "./authenticator-components/internal/Logo";
import NavigationActions from "./authenticator-components/internal/NavigationActions";
import NavigationBreadCrumbs from "./authenticator-components/internal/NavigationBreadCrumbs";
import UserGreeting from "./authenticator-components/internal/UserGreeting";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F5F7F7",
    flex: 1,
    overflow: "overlay", // scrollbar will overlay in the padding of the main div if necessary
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  main: {
    padding: theme.spacing(3),
    flex: "1 1 auto",
    minWidth: 480,
    overflow: "auto"
  },
  container: {
    display: "flex",
    position: "relative",
    padding: 0,
    borderBottom: "1px solid #dfe6ed",
    backgroundColor: "#ffffff"
  },
  navBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 0,
    flex: 1,
    margin: theme.spacing(1)
  }
}));

const Shell = ({
  routes,
  history
}: {
  routes: RouteModule[];
  history: History;
}) => {
  const { root, main, container, navBar } = useStyles({});
  const { user, signOut } = useAuthenticator(context => [
    context.user,
    context.signOut
  ]);
  const userInfo = {
    signOut,
    name: user?.attributes?.name || "",
    email: user?.attributes?.email || "",
    familyName: user?.attributes?.family_name || ""
  };
  const debug = localStorage.getItem("debug") === "true";
  return (
    <>
      <AppBar position="static">
        <Container className={container} maxWidth={false}>
          <Logo />
          <div className={navBar}>
            <NavigationBreadCrumbs />
            <NavigationActions>
              <UserGreeting {...userInfo} />
            </NavigationActions>
          </div>
        </Container>
      </AppBar>
      <div className={root}>
        {/* The MaintenanceBanner should be displayed here */}
        <main className={main}>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              {routes?.map(({ path, title, View }, index) => {
                return (
                  <Route key={index} exact path={path}>
                    <SolutionKeyProvider>
                      <RestrictedRealmContextProvider debug={debug}>
                        <Title title={title}>
                          <View />
                        </Title>
                      </RestrictedRealmContextProvider>
                    </SolutionKeyProvider>
                  </Route>
                );
              })}
              <Route path="/" component={InvalidRoute} />
            </Switch>
          </Suspense>
        </main>
      </div>
    </>
  );
};

export default observer(Shell);
