import { gql, useQuery } from "@apollo/client";
import { Grid, LinearProgress } from "@mui/material";
import { ErrorBox } from "components/error";
import WorkspaceCard from "./WorkspaceCard";
import { QueryAllWorkspaces } from "./schema/QueryAllWorkspaces";

export const workspacesQuery = gql`
  query QueryAllWorkspaces {
    workspaces {
      workspaces {
        id
        name
        isAvailable
        capacity {
          computeCapacityStatus {
            inUse
            available
          }
        }
      }
    }
  }
`;

export default function Workspaces() {
  const {
    loading,
    data,
    error: workspacesError
  } = useQuery<QueryAllWorkspaces>(workspacesQuery);
  const workspaces = data?.workspaces?.workspaces || [];
  if (loading) return <LinearProgress />;

  if (workspacesError)
    return (
      <ErrorBox
        title="An error occurred while loading the workspaces"
        apolloError={workspacesError}
      />
    );
  return (
    <Grid container spacing={4}>
      {workspaces.map(({ id, name, capacity }) => (
        <Grid item key={id}>
          <Grid container spacing={2}>
            <Grid item>
              <WorkspaceCard
                workspaceId={id}
                available={capacity?.computeCapacityStatus.available}
                name={name}
                loggedInUsers={capacity?.computeCapacityStatus.inUse}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
