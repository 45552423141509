import { ErrorOutline } from "@mui/icons-material";
import { SolutionNameVariables } from "./hooks/schema/SolutionName";
import useSolutionName from "./hooks/useSolutionName";

export default function SolutionName({ solutionKey }: SolutionNameVariables) {
  const { data, error } = useSolutionName({ solutionKey });
  const solutionName = data?.solutions.solution?.name;
  return error ? (
    <ErrorOutline color="secondary" />
  ) : (
    <div>{solutionName || solutionKey}</div>
  );
}
