import { useRequestUserPublicApiPermissions } from "features/workspace";
import { WorkspacePermissions } from ".";
import RestrictedRealm, { BasicRestrictedRealmProps } from "./RestrictedRealm";

export default function RestrictedWorkspaceRealm({
  requiredPermissions,
  silent = false,
  children,
  fallback
}: BasicRestrictedRealmProps<WorkspacePermissions>) {
  const {
    data: { accessGranted, missingPermissions },
    loading,
    error
  } = useRequestUserPublicApiPermissions(requiredPermissions);
  return (
    <RestrictedRealm<WorkspacePermissions>
      requiredPermissions={requiredPermissions}
      accessGranted={accessGranted}
      missingPermissions={missingPermissions}
      children={children}
      error={error}
      loading={loading}
      fallback={fallback}
      silent={silent}
      errorTitle="Unable to access workspace permissions"
    />
  );
}
