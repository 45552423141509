import { SolutionPermission } from ".";
import RestrictedRealm, { BasicRestrictedRealmProps } from "./RestrictedRealm";
import useRequestUserSolutionPermissions from "./useRequestUserSolutionPermissions";

export default function RestrictedSolutionRealm({
  requiredPermissions,
  silent = false,
  children,
  fallback
}: BasicRestrictedRealmProps<SolutionPermission>) {
  const {
    data: { accessGranted, missingPermissions },
    loading,
    error
  } = useRequestUserSolutionPermissions(requiredPermissions);

  return (
    <RestrictedRealm<SolutionPermission>
      requiredPermissions={requiredPermissions}
      accessGranted={accessGranted}
      missingPermissions={missingPermissions}
      children={children}
      error={error}
      loading={loading}
      fallback={fallback}
      silent={silent}
      errorTitle="Unable to access solution member permissions"
    />
  );
}
