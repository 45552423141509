import DeleteIcon from "@mui/icons-material/PersonRemove";
import { IconButton, Tooltip } from "@mui/material";
import { Members_solutions_solution_access_users } from "./hooks/schema/Members";

export default function DeleteSolutionMemberAction(params: {
  data: Members_solutions_solution_access_users;
  handleUserDeleteRequest: (userName: string) => void;
  solutionName: string;
}) {
  const {
    data: { name: userName, firstName, lastName },
    handleUserDeleteRequest,
    solutionName
  } = params;

  return (
    <Tooltip
      title={
        <>
          Remove
          <b>{` ${firstName} ${lastName} `}</b>
          from solution <b>{` ${solutionName} `}</b>
        </>
      }
    >
      <IconButton
        data-testid="delete-member"
        onClick={() => {
          handleUserDeleteRequest(userName);
        }}
        size="large"
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}
