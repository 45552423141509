import { notNullOrUndefined } from "utils/typescript";
import {
  QueryCalculationSchema_solutions_solution_useCases_useCases_calculation_inputData_tableSet_tables_schema as InputDataSchema,
  QueryCalculationSchema_solutions_solution_useCases_useCases_calculation,
  QueryCalculationSchema_solutions_solution_useCases_useCases_calculation_resultData_tableSet_tables_schema as ResultDataSchema
} from "./hooks/schema/QueryCalculationSchema";

export const extractCalculationData = (
  calculationData: QueryCalculationSchema_solutions_solution_useCases_useCases_calculation
) => {
  const {
    detail: { state, error: calculationError },
    inputData: { tableSet: inputTableSet },
    resultData
  } = calculationData;

  const inputSchema = inputTableSet.tables
    .filter(notNullOrUndefined)
    .map(t => t.schema);
  const outputSchema = resultData?.tableSet.tables
    .filter(notNullOrUndefined)
    .map(t => t.schema);

  const columnDefs = (
    schemaName: string,
    inputSchema?: (InputDataSchema | ResultDataSchema)[]
  ) => {
    const schema = inputSchema?.find(schema => schema.name === schemaName);
    const alignment: "left" | "right" | "center" | undefined = "left";
    return (
      schema?.columns.map(col => {
        return {
          colDef: {
            dataField: col.name,
            caption: col.name,
            allowFiltering: false,
            allowSorting: false,
            alignment,
            ...col
          },
          typeName: col.typeName
        };
      }) || []
    );
  };

  return {
    state,
    inputSchema,
    outputSchema,
    calculationError,
    inputColumnDefs: (schemaName: string) =>
      columnDefs(schemaName, inputSchema),
    outputColumnDefs: (schemaName: string) =>
      columnDefs(schemaName, outputSchema)
  };
};
