import React, { useContext } from "react";

type RestrictedRealmContextType = {
  debug?: boolean;
};

export interface RestrictedRealmContextProviderProps
  extends RestrictedRealmContextType {
  children: React.ReactNode;
}

const RestrictedRealmContext = React.createContext<RestrictedRealmContextType>({
  debug: false
});

export function useRestrictedRealmContext() {
  return useContext(RestrictedRealmContext);
}

export default function RestrictedRealmContextProvider({
  children,
  ...contextProps
}: RestrictedRealmContextProviderProps) {
  return (
    <RestrictedRealmContext.Provider value={contextProps}>
      {children}
    </RestrictedRealmContext.Provider>
  );
}
