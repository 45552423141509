import { gql, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import { Select } from "components/select";
import { Dispatch, FormEvent } from "react";
import { notNullOrUndefined } from "utils/typescript";
import { useQueryGetAvailableGroups } from "./hooks/membersHooks";
import { Action, State } from "./hooks/useInviteSolutionMemberReducer";
import MemberGroups from "./MemberGroups";
import {
  QueryAllUsers,
  QueryAllUsers_users_allUsers as User
} from "./schema/QueryAllUsers";
import UserName from "./UserName";

const allUsersQuery = gql`
  query QueryAllUsers($solutionKey: Key!) {
    users {
      allUsers {
        firstName
        lastName
        name
        email
      }
    }
    solutions {
      solution(solutionKey: $solutionKey) {
        access {
          users {
            name
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "&.MuiInput-underline:after": {
        borderBottomColor: "#3AA9AE"
      },
      "&.MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#3AA9AE"
        }
      }
    },
    select: {
      margin: theme.spacing(2, 0, 1, 0)
    },
    error: {
      margin: theme.spacing(0, 0, 2, 0)
    }
  })
);

interface InviteMemberFormProps {
  solutionKey: string;
  state: State;
  dispatch: Dispatch<Action>;
  onSubmit: (event: FormEvent) => void;
}

export default function InviteMemberForm({
  solutionKey,
  state,
  dispatch,
  onSubmit
}: InviteMemberFormProps) {
  const classes = useStyles();
  const {
    data: availableGroupsData,
    loading: loadingGroups,
    error: errorGroups
  } = useQueryGetAvailableGroups(solutionKey);
  const groupNames =
    availableGroupsData?.solutions.solution?.access?.availableGroups.map(
      group => group.name
    ) ?? [];

  const {
    data: allUsersData,
    loading: loadingUsers,
    error: errorUsers
  } = useQuery<QueryAllUsers>(allUsersQuery, { variables: { solutionKey } });
  const solutionUsers =
    allUsersData?.solutions.solution?.access?.users?.map(u => u?.name) ?? [];
  const allUsers =
    allUsersData?.users.allUsers?.filter(
      u => !solutionUsers.includes(u.name)
    ) ?? [];

  const errors = [errorUsers, errorGroups].filter(notNullOrUndefined);
  if (errors.length > 0) {
    return (
      <Grid container className={classes.error}>
        <ErrorBox apolloError={errors} />
      </Grid>
    );
  }

  return (
    <form id="memberForm" onSubmit={onSubmit}>
      <Grid container direction="column">
        <Grid item className={classes.select}>
          {
            <Select<User>
              loading={loadingUsers}
              options={allUsers}
              value={state.user}
              getOptionLabel={option =>
                `${option.firstName} ${option.lastName} (${option.name})`
              }
              renderOption={option =>
                option ? <UserName data={option} /> : null
              }
              label="User"
              id="invite-user-select"
              onChange={(_, value) => {
                dispatch({
                  type: "SET_USER",
                  value: value
                });
              }}
            />
          }
        </Grid>
        <Grid item className={classes.select}>
          <MemberGroups
            memberGroups={state.groups}
            allGroups={groupNames}
            loadingGroups={loadingGroups}
            onMemberGroupUpdated={groups => {
              dispatch({
                type: "UPDATE_GROUPS",
                value: groups
              });
            }}
            label="Groups"
            height={56}
          />
        </Grid>
      </Grid>
    </form>
  );
}
