import { useApolloClient } from "@apollo/client";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DataGrid, {
  Button,
  Column,
  Editing,
  Item,
  Lookup,
  MasterDetail,
  RequiredRule,
  Selection,
  Toolbar
} from "devextreme-react/data-grid";
import { DropDownOptions } from "devextreme-react/lookup";
import { EditorPreparingEvent } from "devextreme/ui/data_grid";
import notify from "devextreme/ui/notify";
import {
  PublicApiPermission,
  RestrictedPublicApiRealm
} from "features/access-management";
import { useMemo } from "react";
import "../../utils/custom-dx-theme/dx.material.custom-scheme.css";
import ApiKeyDetail from "./ApiKeyDetail";
import { createStore } from "./ApiKeyStore";

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(1), backgroundColor: "#fff" },
  dataGrid: {
    "& .dx-button[aria-label='Add an API Key']": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "4px !important"
    },
    "& .dx-toolbar-text-auto-hide .dx-button .dx-icon": {
      color: "#fff"
    },
    "& .dx-field-item-label-location-top": {
      paddingLeft: 0
    }
  }
}));
export default function ApiKeys() {
  const classes = useStyles();
  const transactionPackages = ["Small", "Medium", "Large"];
  const client = useApolloClient();
  const store = useMemo(() => createStore(client), [client]);

  function onEditorPreparing(e: EditorPreparingEvent<any, any>) {
    // setReadOnlyOnEditorPreparing(e, ["Name"]);
  }

  function selectRow(e: any) {
    const selectedRows = e.component.getSelectedRowKeys();
    if (selectedRows.length) {
      e.component.collapseAll(-1);
      e.component.expandRow(selectedRows[0]);
    }
  }

  function handleCopy(e: any) {
    const { value, name } = e.row.data;
    navigator.clipboard.writeText(value).then(
      () => {
        notify(
          `API Key for '${name}' was copied to clipboard. Keep the API key confidential and give it only to the users for whom the API key was created.`,
          "success",
          8000
        );
      },
      () => {
        notify(
          "API Key was not copied. There are insufficient permissions for this action.",
          "error",
          4000
        );
      }
    );
  }

  return (
    <RestrictedPublicApiRealm
      requiredPermissions={[PublicApiPermission.ReadApiKeys]}
    >
      <RestrictedPublicApiRealm
        requiredPermissions={[PublicApiPermission.ChangeApiKeys]}
        silent={true}
      >
        {({ accessGranted: changeApiKeys }) => {
          return (
            <RestrictedPublicApiRealm
              requiredPermissions={[PublicApiPermission.ReadApiKeyAccess]}
              silent={true}
            >
              {({ accessGranted: readApiKeyAccess }) => {
                return (
                  <div className={classes.root}>
                    <DataGrid
                      dataSource={store}
                      columnAutoWidth
                      onEditorPreparing={onEditorPreparing}
                      onSelectionChanged={selectRow}
                      onEditingStart={selectRow}
                      onRowInserting={selectRow}
                      onInitNewRow={e => {
                        e.data.enabled = true;
                      }}
                      showBorders={true}
                      className={classes.dataGrid}
                    >
                      {changeApiKeys && (
                        <Editing
                          mode="form"
                          allowUpdating
                          allowAdding
                          allowDeleting
                          useIcons
                          texts={{
                            addRow: "Add an API Key"
                          }}
                        />
                      )}
                      <Column dataField="name" caption="Name">
                        <RequiredRule />
                      </Column>
                      <Column
                        dataField="transactionPackage"
                        caption="Transaction Package"
                        width={150}
                      >
                        <RequiredRule />
                        <Lookup dataSource={transactionPackages}>
                          <DropDownOptions showTitle={false} />
                        </Lookup>
                      </Column>
                      <Column
                        dataField="enabled"
                        dataType="boolean"
                        width={70}
                      />
                      <Column type="buttons">
                        <Button
                          hint="Copies the value of the API key to the clipboard. "
                          icon="copy"
                          onClick={handleCopy}
                        />

                        <Button name="edit" />
                        <Button name="delete" />
                      </Column>
                      <Toolbar>
                        <Item name="addRowButton" />
                        <Item location="before">
                          <Typography>API Keys</Typography>
                        </Item>
                      </Toolbar>
                      {readApiKeyAccess ? <Selection mode="single" /> : null}
                      {readApiKeyAccess ? (
                        <MasterDetail
                          enabled={false}
                          component={ApiKeyDetail}
                        />
                      ) : null}
                    </DataGrid>
                  </div>
                );
              }}
            </RestrictedPublicApiRealm>
          );
        }}
      </RestrictedPublicApiRealm>
    </RestrictedPublicApiRealm>
  );
}
