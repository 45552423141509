import {
  AtLeastOnePermission,
  PublicApiPermission,
  missingPermissions
} from "features/access-management";
import usePublicApiPermissions from "./usePublicApiPermissions";

export const useRequestUserPublicApiPermissions = (
  requestedPermissions: AtLeastOnePermission<PublicApiPermission>
) => {
  const { data: permissionsData, ...queryResult } = usePublicApiPermissions();
  const myPermissions = permissionsData?.publicApi?.access?.myPermissions || [];
  const myMissingPermissions = missingPermissions(
    requestedPermissions,
    myPermissions
  );
  return {
    ...queryResult,
    ...myMissingPermissions
  };
};
