import { gql, useQuery } from "@apollo/client";
import { trimEnd } from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";

import { useSolutionKey } from "features/solution";
import { LatestVersion, LatestVersionVariables } from "./schema/LatestVersion";

const queryLatestVersion = gql`
  query LatestVersion($solutionKey: Key!, $useCaseKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey) {
            detail {
              version
            }
          }
        }
      }
    }
  }
`;

const RedirectToLatestUseCaseVersion: React.FC<{
  url: string;
  useCaseKey: string;
}> = ({ url, useCaseKey }) => {
  const solutionKey = useSolutionKey();
  const { data, loading } = useQuery<LatestVersion, LatestVersionVariables>(
    queryLatestVersion,
    { variables: { solutionKey, useCaseKey } }
  );
  if (loading) return <span> redirect to latest version ... </span>;
  if (!data) return null;
  if (!data.solutions.solution?.useCases?.useCase) return null;
  const useCaseVersion =
    data.solutions.solution?.useCases?.useCase?.detail.version;
  return <Redirect to={`${trimEnd(url, "/")}/${useCaseVersion}`} />;
};

export default RedirectToLatestUseCaseVersion;
