import { getConfigValue } from "utils/config";

const amplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: getConfigValue("REGION"),
    userPoolId: getConfigValue("USER_POOL_ID"),
    userPoolWebClientId: getConfigValue("USER_POOL_WEB_CLIENT_ID"),
    identityPoolId: getConfigValue("IDENTITY_POOL_ID")
  },
  oauth: {
    domain: getConfigValue("USER_POOL_DOMAIN"),
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: getConfigValue("CLOUDFRONT_URL"),
    redirectSignOut: getConfigValue("CLOUDFRONT_URL"),
    responseType: "code"
  },
  Storage: {
    bucket: getConfigValue("DATA_SOURCE_UNVERIFIED_BUCKET"),
    identityPoolId: getConfigValue("IDENTITY_POOL_ID"),
    region: getConfigValue("REGION")
  }
};
export default amplifyConfig;
