import { useAuthenticator } from "@aws-amplify/ui-react";
import { Grid, LinearProgress } from "@mui/material";
import { createBrowserHistory } from "history";
import getRoutes from "pages/Routes";
import { getConfigValue } from "utils/config";
import Shell from "./Shell";
import "./authenticator-components/authenticator-components.css";
import AppInformation from "./authenticator-components/internal/AppInformation";
import CustomAuthenticator from "./authenticator-components/internal/CustomAuthenticator";
import ImprintFooter from "./authenticator-components/internal/ImprintFooter";

const ShellWithAuthenticator = () => {
  const history = createBrowserHistory();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  const environment = (getConfigValue("ENVIRONMENT") || "").replace("_", " ");

  return (
    <>
      {authStatus === "configuring" && <LinearProgress />}
      {authStatus !== "authenticated" && (
        <div className="root">
          <Grid container className="grid">
            <Grid item xs={12} md={5} lg={4} className="left">
              <div className="authenticatorWrapper">
                <CustomAuthenticator />
              </div>
            </Grid>
            {authStatus === "unauthenticated" && (
              <AppInformation environment={environment} />
            )}
          </Grid>
          {authStatus === "unauthenticated" && <ImprintFooter />}
        </div>
      )}
      {authStatus === "authenticated" && (
        <Shell routes={getRoutes()} history={history} />
      )}
    </>
  );
};

export default ShellWithAuthenticator;
