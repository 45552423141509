import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useWorkspaceCapacity } from "./workspaceHooks";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 4, 2, 4),
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    color: "rgba(102, 102, 102, 0.87)"
  }
}));
export default function NoStreamingInstancesAvailable({
  workspaceId,
  unavailable
}: {
  workspaceId: string;
  unavailable: boolean;
}) {
  const classes = useStyles();
  const { data } = useWorkspaceCapacity({ workspaceId });
  const computeCapacityStatus =
    data?.workspaces.workspace?.capacity?.computeCapacityStatus;
  if (!computeCapacityStatus) return null;

  const pendding =
    computeCapacityStatus.desired - computeCapacityStatus.running;
  return unavailable && pendding > 0 ? (
    <div className={classes.root}>
      <Typography>
        There is currently no available seat, there will be new seats for you
        soon
      </Typography>
    </div>
  ) : null;
}
