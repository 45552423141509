import { ErrorOutline } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { useWorkspaceNameById } from "./workspaceHooks";

const useStyles = makeStyles(() => ({
  root: {
    "&:first-letter": {
      textTransform: "uppercase"
    }
  }
}));
interface WorkspaceNameProps {
  solutionKey: string;
  workspaceId: string;
}

export default function WorkspaceNavigationName({
  solutionKey,
  workspaceId
}: WorkspaceNameProps) {
  const { root } = useStyles({});
  const { data, error } = useWorkspaceNameById({
    workspaceId
  });
  const workspace = data?.workspaces?.workspace;
  const name = data?.workspaces.workspace?.name;
  return workspace === null || error ? (
    <ErrorOutline color="secondary" />
  ) : (
    <div data-testid="workspace-nav" className={root}>
      {name || workspaceId}
    </div>
  );
}
