import { LinearProgress, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ErrorBox from "components/error/ErrorBox";
import {
  RestrictedWorkspaceRealm,
  WorkspacePermissions
} from "features/access-management";
import { InvalidRouteBase } from "features/shell";
import { notNullOrUndefined } from "utils/typescript";
import Capacity from "./capacity/Capacity";
import CapacityScaling from "./capacity/CapacityScaling";
import CapacityStatus from "./capacity/CapacityStatus";
import {
  useMutationUpdateScalingPolicy,
  useQueryWorkspace
} from "./workspaceHooks";

export const useStyles = makeStyles(theme => ({
  topGrid: {
    padding: theme.spacing(3),
    minWidth: 800,
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(2),
    display: "grid",
    gridTemplateAreas: `
    "capacity capacity"
    "scaling scaling"
    `
  },
  bottomGrid: {
    padding: theme.spacing(2),
    columnGap: `${theme.spacing(3)}px`,
    gridTemplateColumns: "350px minmax(0, 1fr)",
    display: "grid",
    gridTemplateAreas: `
    "diagram sessions"
    `,
    margin: theme.spacing(2, 0)
  },
  capacityUse: {
    gridArea: "capacityUse",
    justifySelf: "end"
  },
  diagram: {
    gridArea: "diagram"
  },
  scaling: {
    gridArea: "scaling"
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  margin: { marginTop: theme.spacing(3) }
}));

export default function Workspace({ workspaceId }: { workspaceId: string }) {
  const classes = useStyles();
  const {
    data: workspaceData,
    error: workspaceError,
    loading: workspaceLoading
  } = useQueryWorkspace({
    workspaceId
  });

  const { updateScalingPolicy, error: scalingError } =
    useMutationUpdateScalingPolicy({ workspaceId });

  if (workspaceLoading) return <LinearProgress />;

  const errors = [workspaceError, scalingError].filter(notNullOrUndefined);
  if (errors.length > 0) {
    return <ErrorBox apolloError={errors} />;
  }
  const workspace = workspaceData?.workspaces?.workspace!;
  // workspace is only null if there is an error
  if (!workspace)
    return (
      <InvalidRouteBase>
        <Typography>There is no workspace with the given id.</Typography>
      </InvalidRouteBase>
    );
  return (
    <RestrictedWorkspaceRealm
      requiredPermissions={[WorkspacePermissions.ReadWorkspaces]}
    >
      <RestrictedWorkspaceRealm
        requiredPermissions={[WorkspacePermissions.ChangeWorkspaces]}
      >
        {({ accessGranted: canEditWorkspace }) => (
          <>
            <Paper className={classes.topGrid}>
              <Capacity editable={canEditWorkspace} workspaceId={workspaceId} />
              <CapacityScaling
                className={classes.scaling}
                scaleInPolicy={workspace.scaleInPolicy!}
                scaleOutPolicy={workspace.scaleOutPolicy!}
                editable={canEditWorkspace}
                onUpdate={policy => {
                  updateScalingPolicy({ ...policy, workspaceId });
                }}
                workspaceId={workspaceId}
              />
            </Paper>
            <Paper className={classes.bottomGrid}>
              <CapacityStatus
                className={classes.diagram}
                capacity={workspace.capacity!}
              />
            </Paper>
          </>
        )}
      </RestrictedWorkspaceRealm>
    </RestrictedWorkspaceRealm>
  );
}
