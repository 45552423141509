import { gql, useQuery } from "@apollo/client";
import {
  DatabaseBackups,
  DatabaseBackupsVariables
} from "./schema/DatabaseBackups";

const databaseBackupsList = gql`
  query DatabaseBackups($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          databaseTemplates {
            id
            name
          }
        }
      }
    }
  }
`;

export const useDatabaseBackups = (solutionKey: string) =>
  useQuery<DatabaseBackups, DatabaseBackupsVariables>(databaseBackupsList, {
    variables: { solutionKey }
  });
