import { useMutation } from "@apollo/client";
import { LinearProgress, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { ErrorBox } from "components/error";
import { useSolutionKey } from "features/solution";
import { useEffect } from "react";
import { mutationCreateStreamingUrl } from "./ProjectStreamingButtons";
import {
  CreateStreamingUrl,
  CreateStreamingUrlVariables
} from "./schema/CreateStreamingUrl";

type ConnectProjectProps = {
  projectId: string;
};

export default function ConnectProject({ projectId }: ConnectProjectProps) {
  const [createStreamingUrlMutation, { error: mutationError }] = useMutation<
    CreateStreamingUrl,
    CreateStreamingUrlVariables
  >(mutationCreateStreamingUrl, { onError: error => console.log(error) });
  const solutionKey = useSolutionKey();
  useEffect(() => {
    if (projectId != null) {
      const connectToAppstream = async (
        projectId: string,
        solutionKey: string
      ) => {
        const auth = await Auth.currentSession();
        const refreshToken = auth.getRefreshToken().getToken();
        const mutation = await createStreamingUrlMutation({
          variables: {
            projectId,
            refreshToken,
            solutionKey
          }
        });
        const streamingUrl =
          mutation.data?.solutions.solution?.projects?.createStreamingUrl;
        if (streamingUrl) window.location.href = streamingUrl;
      };
      connectToAppstream(projectId, solutionKey);
    }
  }, [projectId, createStreamingUrlMutation, solutionKey]);
  return mutationError ? (
    <ErrorBox apolloError={mutationError} />
  ) : (
    <>
      <LinearProgress />
      <Typography>Connecting to project...</Typography>
    </>
  );
}
