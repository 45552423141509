import { RenderableColumnDefinition } from "components/grid/gridTypings";
import { IColumnProps } from "devextreme-react/data-grid";
import Header from "./Header";
import TableSetGrid from "./TableSetGrid";
import { useStyles } from "./tableSetStyle";

type TableSetItemProps = {
  columnDefs: (
    schemaName: string
  ) => RenderableColumnDefinition<IColumnProps, {}>[];
  dataKey?: string;
  calculationId: string;
  useCaseKey: string;
  useCaseVersion?: string | null;
  loadData: any;
  toCalculationResultTable?: (name: string) => string;
  backgroundColor: string;
  name: string;
};

const TableSetItem = ({
  columnDefs,
  dataKey,
  calculationId,
  useCaseKey,
  useCaseVersion = null,
  loadData,
  toCalculationResultTable,
  backgroundColor,
  name
}: TableSetItemProps) => {
  const { flexContainer, tableView } = useStyles({});
  return (
    <div className={flexContainer}>
      <div key={name} className={tableView}>
        <Header
          name={name}
          tableDeepLinks={true}
          toCalculationResultTable={
            toCalculationResultTable && toCalculationResultTable(name)
          }
          backgroundColor={backgroundColor}
        />
        <TableSetGrid
          key={name}
          dataKey={dataKey}
          columnDefs={columnDefs}
          name={name}
          useCaseKey={useCaseKey}
          useCaseVersion={useCaseVersion}
          calculationId={calculationId}
          loadData={loadData}
        />
      </div>
    </div>
  );
};
export default TableSetItem;
