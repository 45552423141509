export function tooltipFormatter(
  date: Date | string | null | undefined,
  locales?: Intl.LocalesArgument
): string {
  if (typeof date === "string" && date !== "") {
    date = new Date(date);
  }
  if (date instanceof Date)
    return date.toLocaleString(locales, {
      timeZone: "UTC",
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short"
    });
  return "";
}

export function valueFormatter(
  date: Date | string | null | undefined,
  locales?: Intl.LocalesArgument
): string {
  if (typeof date === "string" && date !== "") {
    date = new Date(date);
  }
  if (date instanceof Date) {
    return date.toLocaleString(locales, {
      timeZone: "UTC",
      year: "numeric",
      month: "short",
      day: "numeric"
    });
  }
  return "";
}
