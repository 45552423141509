import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  SolutionName as SolutionNameQuery,
  SolutionNameVariables
} from "./schema/SolutionName";

const querySolutionName = gql`
  query SolutionName($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        name
      }
    }
  }
`;

export default function useSolutionName(variables: SolutionNameVariables) {
  return useQuery<SolutionNameQuery, SolutionNameVariables>(querySolutionName, {
    variables,
    fetchPolicy: "no-cache"
  });
}
