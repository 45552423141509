import CardWithRoles, {
  CardWithRolesProps
} from "components/card/CardWithRoles";
import {
  AtLeastOnePermission,
  RestrictedSolutionRealm,
  SolutionPermission
} from "features/access-management";

type SpaceCardProps = CardWithRolesProps & {
  requiredPermissions: AtLeastOnePermission<SolutionPermission>;
};

export default function SpaceCard({
  requiredPermissions,
  enabled = true,
  ...cardProps
}: SpaceCardProps) {
  return (
    <RestrictedSolutionRealm requiredPermissions={requiredPermissions}>
      {({ accessGranted }) => {
        return (
          <CardWithRoles {...cardProps} enabled={enabled && accessGranted} />
        );
      }}
    </RestrictedSolutionRealm>
  );
}
