import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useRestrictedRealmContext } from "./RestrictedRealmContextProvider";

interface RealmPermissions<TPermission> {
  missingPermissions: (TPermission | null)[] | undefined;
  accessGranted: boolean;
  requiredPermissions: TPermission[];
}
interface RestrictedRealmDebuggerProps<TPermission>
  extends RealmPermissions<TPermission> {
  children: React.ReactNode;
}

interface StyleProps {
  accessGranted: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  debugElement: ({ accessGranted }) => ({
    minWidth: 10,
    minHeight: 10,
    border: "dashed 2px",
    borderOpacity: 0.7,
    borderColor: accessGranted ? "green" : "red",
    margin: theme.spacing(1)
  }),
  iconContainer: {
    display: "relative",
    zIndex: 9999
  },
  icon: ({ accessGranted }) => ({
    position: "absolute",

    color: accessGranted ? "green" : "red"
  })
}));

const RestrictedRealmDebugger = <TPermission extends {}>({
  children,
  ...permissionResult
}: RestrictedRealmDebuggerProps<TPermission>) => {
  const {
    accessGranted,
    missingPermissions = [],
    requiredPermissions
  } = permissionResult;
  const classNames = useStyles({ accessGranted });

  const { debug } = useRestrictedRealmContext();
  if (!debug) return <>{children}</>;

  const Icon = accessGranted ? LockOpenIcon : LockIcon;
  const permissions = accessGranted ? requiredPermissions : missingPermissions;

  return (
    <div className={classNames.debugElement}>
      {requiredPermissions.length > 0 && (
        <div className={classNames.iconContainer}>
          <div className={classNames.icon}>
            <Tooltip title={permissions?.join("\n")}>
              <Icon fontSize="small" />
            </Tooltip>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
export default RestrictedRealmDebugger;
