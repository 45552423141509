import { Button, Divider, Flex } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { getConfigValue } from "utils/config";
import "../authenticator-components.css";

const CustomSSOButtons = () => {
  const { pathname } = useLocation();
  const siemensProvider = getConfigValue("FEDERATED_SIEMENS_PROVIDER");
  const customerProvider = getConfigValue("FEDERATED_CUSTOMER_PROVIDER");
  const environmentName = (getConfigValue("ENVIRONMENT") || "").replace(
    "_",
    " "
  );

  const hasFederatedSiemensLogin = siemensProvider && !isEmpty(siemensProvider);
  const hasFederatedCustomerLogin =
    customerProvider && !isEmpty(customerProvider);

  const ssoClickEvent =
    (provider: string) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      Auth.federatedSignIn({
        customProvider: provider,
        customState: pathname
      });
    };
  return (
    <Flex padding={`0 32px`} direction={"column"}>
      {hasFederatedSiemensLogin && (
        <Button
          className="sso-button"
          data-testid="sso-siemens"
          onClick={ssoClickEvent(siemensProvider)}
        >
          SSO (Siemens)
        </Button>
      )}
      {hasFederatedCustomerLogin && environmentName && (
        <Button
          className="sso-button"
          data-testid="sso-customer"
          onClick={ssoClickEvent(customerProvider)}
        >
          {`SSO (${environmentName})`}
        </Button>
      )}
      {(hasFederatedSiemensLogin || hasFederatedCustomerLogin) && (
        <Divider label="or" />
      )}
    </Flex>
  );
};
export default CustomSSOButtons;
