import { Tooltip } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { UserName } from "features/solution-member";
import prettyBytes from "pretty-bytes";
import { useCallback, useEffect, useState } from "react";
import "./dataGridView.css";
import { tooltipFormatter } from "./dateFormatter";

export const DateCellRenderer = ({ value }: { value?: string | Date }) => {
  if (!value) return;
  const date = value instanceof Date ? value : new Date(Date.parse(value));
  return (
    <Tooltip title={tooltipFormatter(value)} classes={{ tooltip: "tooltip" }}>
      <div>
        {formatDistanceToNow(date, {
          includeSeconds: true,
          addSuffix: true
        })}
      </div>
    </Tooltip>
  );
};

export const BytesCellRenderer = ({ value }: { value?: number }) => {
  if (!value) return;
  return (
    <Tooltip title={value} classes={{ tooltip: "tooltip" }}>
      <div>{prettyBytes(value)}</div>
    </Tooltip>
  );
};

export const DateTickerCellRenderer = ({ value }: { value: number | Date }) => {
  const [date, setDate] = useState(
    formatDistanceToNow(value, {
      includeSeconds: true,
      addSuffix: true
    })
  );
  const tick = useCallback(() => {
    setDate(
      formatDistanceToNow(value, {
        includeSeconds: true,
        addSuffix: true
      })
    );
  }, [value]);

  useEffect(() => {
    const intervalID = setInterval(() => tick(), 15000);
    return () => clearInterval(intervalID);
  }, [tick]);

  return date;
};

export const UserNameCellRenderer = ({ user }: any) => {
  return <UserName data={user} />;
};
