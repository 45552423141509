import { useReducer } from "react";
import { generateKeyFromName } from "utils/addForm";

export interface State {
  name: string;
  key: string;
  autoGeneratedKey: boolean;
  description: string;
  projectsEnabled?: boolean;
  useCasesEnabled?: boolean;
}

export type Action =
  | { type: "SET_NAME"; value: string }
  | { type: "SET_KEY"; value: string }
  | { type: "SET_DESCRIPTION"; value: string }
  | { type: "PROJECTS_ENABLED"; value: boolean }
  | { type: "USECASES_ENABLED"; value: boolean }
  | { type: "GENERATE_KEY" }
  | { type: "RESET" };

export default function useAddSolutionReducer(initialState: State) {
  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case "SET_NAME":
        if (state.autoGeneratedKey) {
          const key = generateKeyFromName(action.value);
          return { ...state, name: action.value, key };
        }
        return { ...state, name: action.value };
      case "SET_KEY":
        return { ...state, key: action.value, autoGeneratedKey: false };
      case "SET_DESCRIPTION":
        return { ...state, description: action.value };
      case "PROJECTS_ENABLED":
        return { ...state, projectsEnabled: action.value };
      case "USECASES_ENABLED":
        return { ...state, useCasesEnabled: action.value };
      case "GENERATE_KEY":
        const key = generateKeyFromName(state.name);
        return { ...state, key, autoGeneratedKey: true };
      case "RESET":
        return { ...initialState };
      default:
        throw new Error();
    }
  }

  return useReducer(reducer, initialState);
}
