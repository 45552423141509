import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import copy from "copy-to-clipboard";
import _ from "lodash";
import CopyIcon from "mdi-material-ui/ContentCopy";
import React from "react";
import InfoTooltip from "components/base/InfoTooltip";

interface StylesProps {
  dense: boolean;
}

export const useStyles = makeStyles(theme => ({
  titleBox: {
    padding: ({ dense }: StylesProps) =>
      dense ? theme.spacing(2, 3) : theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  icon: { color: "#ffb900", cursor: "pointer" },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  contentText: {
    padding: ({ dense }: StylesProps) =>
      dense ? theme.spacing(1, 0) : theme.spacing(2),
    overflow: "hidden",
    userSelect: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  contentMultiline: {
    padding: ({ dense }: StylesProps) =>
      dense ? theme.spacing(1, 2) : theme.spacing(2),
    userSelect: "none"
  },
  titleText: {
    userSelect: "none",
    [theme.breakpoints.down('xl')]: {
      whiteSpace: "nowrap"
    },
    color: "rgba(0,0,0,.2)"
  },
  button: {
    padding: theme.spacing(1)
  },
  contentWithCopySkeleton: {
    display: "flex"
  },
  copyIconSkeleton: {
    marginLeft: theme.spacing(1)
  }
}));

type DetailProps = {
  title: string;
  content?: string | null;
  tooltipTitle?: React.ReactNode;
  contentCopy?: boolean;
  multiline?: boolean;
  dense?: boolean;
  loading?: boolean;
  contentSkeletonWidth: number;
};

const Detail: React.FC<DetailProps> = ({
  title,
  content,
  tooltipTitle,
  contentCopy,
  multiline = false,
  dense = false,
  loading,
  contentSkeletonWidth
}) => {
  const {
    titleBox,
    icon,
    flex,
    contentText,
    contentMultiline,
    titleText,
    button,
    contentWithCopySkeleton,
    copyIconSkeleton
  } = useStyles({ dense });
  const [open, setOpen] = React.useState("");
  const handleTooltipClose = () => {
    setOpen("");
  };

  const handleTooltipOpen = (value: string) => {
    setOpen(value);
  };
  return (
    <div className={titleBox}>
      {loading ? (
        <>
          <Skeleton variant="text" width={100} />
          <div className={contentWithCopySkeleton}>
            <Skeleton variant="text" width={contentSkeletonWidth} />
            {contentCopy ? (
              <Skeleton
                variant="rectangular"
                width={20}
                height={20}
                className={copyIconSkeleton}
              />
            ) : null}
          </div>
        </>
      ) : (
        <>
          {tooltipTitle ? (
            <InfoTooltip title={tooltipTitle} typography>
              <Typography className={titleText}>{title}</Typography>
            </InfoTooltip>
          ) : (
            <Typography className={titleText}>{title}</Typography>
          )}

          <div className={flex}>
            <Typography className={multiline ? contentMultiline : contentText}>
              {content}
            </Typography>
            {contentCopy ? (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true
                    }}
                    onClose={handleTooltipClose}
                    open={open === title}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={`${title} was copied`}
                  >
                    <IconButton
                      className={button}
                      onClick={() => {
                        handleTooltipOpen(title);
                        if (_.isString(content)) copy(content);
                      }}
                      size="large">
                      <CopyIcon className={icon} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default Detail;
