import _ from "lodash";
import * as XLSX from "xlsx";

/*
  Transforms a given tableSetSchema to an excel workbook. 
  The workbook contains all tables (sheets named with table name) and each sheet has a header with all columns (internal column names).
  Beside the headers, the sheets are empty.

  Possible extensions: 
    - Column types might be used to format column cells, types and display names might be used as tooltip or cell comments.
    - Client side default values might be generated for some example rows
    - Server side example data might be generated using existing TableSet methods
*/
/**
 * @param {any} tableSetSchema with format {
        name: string,
        displayName?: string,
        columns: {
          displayName?: string
          name: string
          nullable: boolean
          typeName: string
        } []     
    } [] 
 */
export function createWorkbookFromTableSetSchema(tableSetSchema) {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Template Export",
    Author: "Template Generator",
    Subject: "",
    CreatedDate: new Date()
  };

  wb.SheetNames = _(tableSetSchema)
    .map(table => table.name)
    .value();

  wb.Sheets = _(tableSetSchema)
    .keyBy(table => table.name)
    .mapValues(table => {
      const sheetHeader = _(table.columns)
        .map((column, index) => ({
          ref: XLSX.utils.encode_cell({ c: index, r: 0 }),
          v: column.name,
          t: "s"
        }))
        .keyBy(cell => cell.ref)
        .mapValues(v => _.omit(v, "ref"))
        .value();

      return {
        "!ref": XLSX.utils.encode_range(
          {
            c: 0,
            r: 0
          },
          {
            c: table.columns.length - 1,
            r: 0
          }
        ),
        ...sheetHeader
      };
    })
    .value();

  return wb;
}

/*
  Returns the top most cells content of a worksheet. 
  For the range the left most cell and right most data cells are used. This holds whenever the sheet data are in a compact (side by side) manner.
  If there are empty columns, the cells in this header range contain 'null' values. 
*/
export function getSheetHeader(sheet) {
  const { s, e } = XLSX.utils.decode_range(sheet["!ref"]);
  const tableHeaderRange = { s, e: { c: e.c, r: s.r } };

  const header = XLSX.utils.sheet_to_json(sheet, {
    header: 1,
    range: tableHeaderRange,
    raw: false,
    defval: null
  })[0];

  return header;
}

export function compareSchema(workbook, tableSetSchema) {
  const schemaColumns = _(tableSetSchema)
    .map(({ name: table, columns }) => {
      return columns.map(({ name: column }) => ({
        table,
        column
      }));
    })
    .flatten()
    .value();

  const workbookColumns = _(workbook.SheetNames)
    .map(sheetName => {
      const header = getSheetHeader(workbook.Sheets[sheetName]);

      if (header.some(e => !e)) {
        throw new Error(
          `Table ${sheetName} have to be compact. Don't use empty columns between data columns.`
        );
      }

      return header.map(column => ({
        table: sheetName,
        column
      }));
    })
    .flatten()
    .value();

  return _([
    ["MATCH", _.intersectionWith(schemaColumns, workbookColumns, _.isEqual)],
    ["INSERTION", _.differenceWith(schemaColumns, workbookColumns, _.isEqual)],
    ["DELETION", _.differenceWith(workbookColumns, schemaColumns, _.isEqual)]
  ])
    .map(([diff, entries]) => entries.map(item => ({ ...item, diff })))
    .flatten()
    .value();
}

/*
  Exports a workbook to a file. 

  Hint: 
    - when running in node environment the filename could be a file path containing filename and extension.
    - when running on a browser the filename could contain name and extension. On modern browsers a file download is faked.
*/
export function writeWorkbookFile(wb, filename) {
  XLSX.writeFile(wb, filename);
}

/*
  Creates a workbook from binary data.
*/
export function createWorkbook(data) {
  const workbook = XLSX.read(data, { type: "binary" });
  return workbook;
}
