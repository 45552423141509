import { AppBar, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import getRoutes from "pages/Routes";
import { NavigationProps, ViewProps } from "pages/RoutesTyping";

import React, { useEffect, useState } from "react";
import { Link, Route, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles(() => ({
  appBar: {
    boxShadow: "none",
    backgroundColor: "#ffffff",
    minWidth: 0,
    flex: 1
  },
  scrollButtons: {
    color: "gray",
    width: 20
  },
  tab: {
    textTransform: "none",
    minWidth: 0,
    fontSize: 16,
    fontWeight: "normal",
    color: "gray",
    opacity: 1,
    "&:last-child": {
      color: "#000",
      "& $divider": {
        display: "none"
      }
    },
    "&:hover": {
      color: "#000"
    }
  },
  indicator: { display: "none" },
  divider: {
    color: "gray",
    marginLeft: 24
  },
  flex: { display: "flex", alignItems: "center" },
  navigationText: {
    "&:first-letter": {
      textTransform: "capitalize"
    }
  }
}));

const TabRenderer: React.FC<{
  index: number;
  Preview: React.FC<ViewProps>;
  Navigation: React.FC<NavigationProps>;
  setSelectedTab: (selectedTab: number) => void;
  tab: number;
}> = ({ index, Preview, Navigation, setSelectedTab, tab }) => {
  const jss = useStyles();
  const { url } = useRouteMatch();
  useEffect(() => {
    setSelectedTab(tab);
  }, [setSelectedTab, tab]);
  return (
    <Tab
      key={index}
      className={jss.tab}
      label={
        <div className={jss.flex}>
          <Navigation Preview={Preview} text={_.last(url.split("/")) || ""} />
          <div className={jss.divider}>/</div>
        </div>
      }
      component={Link}
      to={url}
      onClick={() => setSelectedTab(tab)}
    />
  );
};

export default function NavigationBreadCrumbs() {
  const { appBar, indicator, navigationText } = useStyles({});
  const [value, setValue] = useState(0);
  const routes = getRoutes();
  const DefaultNavigation: React.FC<NavigationProps> = ({ text }) => (
    <div className={navigationText}>{text}</div>
  );

  return (
    <AppBar position="static" className={appBar}>
      <Tabs
        data-test="navigation-bar"
        aria-label="navigation tabs"
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        classes={{
          indicator: indicator
        }}
      >
        {routes
          .filter(({ Navigation }) => Navigation !== null)
          .map(({ path, Navigation, View, tab }, index) => {
            return (
              <Route key={_.flattenDeep([path]).join()} path={path}>
                <TabRenderer
                  index={index}
                  Preview={View}
                  Navigation={Navigation || DefaultNavigation}
                  tab={tab}
                  setSelectedTab={value => setValue(value)}
                />
              </Route>
            );
          })}
      </Tabs>
    </AppBar>
  );
}
