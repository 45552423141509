import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { UseCaseData_solutions_solution_useCases_useCase_data } from "./schema/UseCaseData";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1)
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: 0,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  }
}));
interface UploadedDataToolsPanelProps {
  history: any;
  scheduleCalculation: (
    row: UseCaseData_solutions_solution_useCases_useCase_data
  ) => void;
  selectedRow: UseCaseData_solutions_solution_useCases_useCase_data;
}

const UploadedDataToolsPanel = ({
  history,
  scheduleCalculation,
  selectedRow
}: UploadedDataToolsPanelProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        data-testid="data-view-button"
        size="small"
        variant="contained"
        className={classes.button}
        onClick={() => {
          const id = selectedRow.detail?.key.split("/").slice(-1)[0];
          history.push({
            pathname: `./data/${id}`,
            state: {
              dataKey: selectedRow.detail?.key
            }
          });
        }}
      >
        Data view
      </Button>

      <Button
        data-testid="schedule-btn"
        size="small"
        variant="contained"
        className={classes.button}
        onClick={() => scheduleCalculation(selectedRow)}
      >
        Schedule calculation
      </Button>
    </div>
  );
};
export default UploadedDataToolsPanel;
