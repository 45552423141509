import { IconButton, SvgIconTypeMap, Tooltip, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import makeStyles from "@mui/styles/makeStyles";
import Link from "components/base/Link";

const useStyles = makeStyles(theme => ({
  noBorderRadius: {
    borderRadius: 0
  },
  link: {
    textDecoration: "none"
  }
}));

export default function LinkIconButton({
  to,
  title,
  testId,
  icon: Icon,
  target
}: {
  to: string;
  title: string;
  testId?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  target?: string;
}) {
  const classNames = useStyles();
  return (
    <Link className={classNames.link} to={to} target={target}>
      <Tooltip title={<Typography>{title}</Typography>}>
        <IconButton
          className={classNames.noBorderRadius}
          color="secondary"
          size="large"
        >
          <Icon data-testid={testId} />
        </IconButton>
      </Tooltip>
    </Link>
  );
}
