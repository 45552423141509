import ConfigKey, { getKeys } from "./configKey";

const useRuntimeConfig = (window as any).config !== undefined;
const configType = useRuntimeConfig ? "Runtime" : "Environment";

/**
 * Returns a configuration value.
 *
 * Hint: Do not use `process.env.REACT_APP_` to access the environment, use this abstraction instead.
 * @param configKey Configuration Key to get the value from
 * @return {string|undefined} Value or undefined if key doesn't exist
 */
export function getConfigValue(configKey: ConfigKey): string | undefined {
  switch (configType) {
    case "Runtime":
      return getRuntimeConfig(configKey);
    case "Environment":
      return getReactAppEnvKey(configKey);
  }
}

function getReactAppEnvKey(configKey: ConfigKey) {
  return process.env[`REACT_APP_${configKey}`];
}

function getRuntimeConfig(configKey: ConfigKey) {
  const config = (window as any).config;
  return config && config[configKey];
}

/**
 * Checks if all possible values of {ConfigKey} have a value set.
 * @throws Config setup failed, when at least one key has no value
 */
export function assertConfigSetup() {
  var missingKeys = getKeys().filter(
    key => getConfigValue(key as ConfigKey) === undefined
  );
  if (missingKeys.length !== 0) {
    throw new Error(
      `${configType} Config setup failed. Missing following keys: ${missingKeys.join(
        ", "
      )}.`
    );
  }
}

/**
 * Prints a table with configuration keys.
 */
export function printConfigSetup() {
  console.group(`Configuration (${configType})`);
  console.table(
    getKeys().map(key => ({
      Name: key,
      Value: getConfigValue(key as ConfigKey)
    }))
  );
  console.groupEnd();
}
