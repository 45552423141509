import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useSolutionKey } from "features/solution";
import { useWorkspaceName } from "./workspaceHooks";

const useStyles = makeStyles(() => ({
  name: { color: "rgba(0,0,0,.2)", fontSize: 12, fontWeight: "bold" }
}));

export default function WorkspaceNameForProject({
  projectId
}: {
  projectId: string;
}) {
  const solutionKey = useSolutionKey();
  const classes = useStyles();
  const { data: workspace } = useWorkspaceName({
    solutionKey,
    projectId
  });

  const workspaceName =
    workspace?.solutions.solution?.projects?.project?.workspaceName;

  return workspaceName ? (
    <Typography className={classes.name}> {workspaceName}</Typography>
  ) : null;
}
