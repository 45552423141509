import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  progress: {
    flex: 1
  }
}));

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.progress}>
        <LinearProgress
          variant="determinate"
          {...props}
          data-testid="loading"
        />
      </Box>
      <Box>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
