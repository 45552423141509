import { Grid, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import { useSolutionKey } from "features/solution";
import { useEffect } from "react";
import { notNullOrUndefined } from "utils/typescript";
import NoProjectFound from "./NoProjectFound";
import Project from "./Project";
import useQueryProjectById from "./hooks/useQueryProjectById";

const useStyles = makeStyles(theme => ({
  button: { color: theme.palette.common.white },
  progress: { marginTop: -4 }
}));

export default function ProjectWrapper({ projectId }: { projectId: string }) {
  const solutionKey = useSolutionKey();
  const classNames = useStyles();
  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
    startPolling,
    stopPolling
  } = useQueryProjectById({
    solutionKey,
    projectId
  });

  const project = projectData?.solutions.solution?.projects?.project;

  useEffect(() => {
    if (project?.state === "Scheduled" || project?.state === "InProgress") {
      startPolling(3000);
    } else {
      stopPolling();
    }
  }, [project?.state, startPolling, stopPolling]);

  if (projectData && !project) {
    return <NoProjectFound />;
  }
  const errors = [projectError].filter(notNullOrUndefined);
  if (errors.length > 0) return <ErrorBox apolloError={errors} />;
  return (
    <Grid item xs={12}>
      {projectLoading && (
        <Grid item xs={12}>
          <LinearProgress className={classNames.progress} />
        </Grid>
      )}
      {project && (
        <Project
          project={project}
          // workspaceStatus={
          //   workspaceStatus?.solutions.solution?.projects?.workspace
          // }
        />
      )}
    </Grid>
  );
}
