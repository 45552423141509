import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { HTMLAttributes } from "react";

import {
  Workspace_workspaces_workspace_scaleInPolicy as ScaleInPolicy,
  Workspace_workspaces_workspace_scaleOutPolicy as ScaleOutPolicy
} from "../schema/Workspace";
import ScalingPolicy from "./ScalingPolicy";

const useStyles = makeStyles(() => ({
  grid: {
    display: "inline-grid",
    gridTemplateColumns:
      "100px minmax(0, 1fr) 70px 110px 65px 75px minmax(0, 1fr)",
    gridTemplateRows: "30px 30px 30px 0px 30px 20px",
    alignItems: "center"
  },
  stretch: {
    gridColumn: "1 / 9"
  },
  bold: {
    fontWeight: "bold"
  },
  center: {
    textAlign: "center"
  },
  left: {
    textAlign: "left"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  rowStart: { gridRowStart: 3 },
  columnStart: { gridColumnStart: 2 }
}));

const CapacityScaling = ({
  scaleInPolicy,
  scaleOutPolicy,
  editable,
  onUpdate,
  className,
  workspaceId
}: {
  scaleInPolicy: ScaleInPolicy;
  scaleOutPolicy: ScaleOutPolicy;
  editable: boolean;
  onUpdate: (policy: ScaleInPolicy | ScaleOutPolicy) => void;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  workspaceId: string;
}) => {
  const { grid, stretch, rowStart } = useStyles();
  return (
    <div className={className}>
      <div className={grid}>
        <Typography className={stretch}></Typography>
        <Typography gutterBottom className={rowStart}>
          Scaling
        </Typography>
        <ScalingPolicy
          scaling={scaleInPolicy}
          editable={editable}
          onUpdate={onUpdate}
          workspaceId={workspaceId}
          min={scaleOutPolicy.threshold?.toString()}
        />
        <Typography className={stretch}></Typography>
        <ScalingPolicy
          scaling={scaleOutPolicy}
          editable={editable}
          onUpdate={onUpdate}
          workspaceId={workspaceId}
          min="1"
          max={scaleInPolicy.threshold?.toString()}
        />
      </div>
    </div>
  );
};

export default CapacityScaling;
