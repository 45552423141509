import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useQueryUseCaseData } from "./topics/calculate/calculationHooks";

interface UploadedDataNameProps {
  solutionKey: string;
  useCaseKey: string;
  useCaseVersion: string | null;
  dataId?: string;
}

const UploadedDataName: FC<UploadedDataNameProps> = ({
  solutionKey,
  useCaseKey,
  useCaseVersion = null,
  dataId
}) => {
  interface LocationState {
    dataKey: string;
  }

  const location = useLocation<LocationState>();
  const dataKey = location.state.dataKey;
  const { data: useCaseData } = useQueryUseCaseData({
    solutionKey,
    useCaseKey,
    useCaseVersion,
    keys: [dataKey]
  });

  const currentData =
    useCaseData?.solutions.solution?.useCases?.useCase?.data.filter(
      d => d.detail?.key.split("/").slice(-1)[0] === dataId
    )[0];
  return <div>{currentData?.detail?.metaData.displayName}</div>;
};

export default UploadedDataName;
