import { gql, useQuery } from "@apollo/client";
import { WorkspaceManagementPermissions } from "./schema/WorkspaceManagementPermissions";

export const workspacePermissions = gql`
  query WorkspaceManagementPermissions {
    workspaces {
      myPermissions
    }
  }
`;
export default function useWorkspacePermissions() {
  return useQuery<WorkspaceManagementPermissions>(workspacePermissions, {
    fetchPolicy: "cache-first"
  });
}
