import { TableChart } from "@mui/icons-material";
import { RenderableColumnDefinition } from "components/grid/gridTypings";
import { IColumnProps } from "devextreme-react/data-grid";
import MultiView from "devextreme-react/multi-view";
import { OptionChangedEvent } from "devextreme/ui/tabs";
import {
  InputDataTablesSchema,
  ResultDataTablesSchema
} from "features/use-case";

import { Tabs } from "devextreme-react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import TableSetItem from "./TableSetItem";
import { useStyles } from "./tableSetStyle";

type TableSetProps = {
  tablesSchema?: InputDataTablesSchema[] | ResultDataTablesSchema[];
  columnDefs: (
    schemaName: string
  ) => RenderableColumnDefinition<IColumnProps, {}>[];
  dataKey?: string;
  calculationId: string;
  useCaseKey: string;
  useCaseVersion?: string | null;
  loadData: any;
  toCalculationResultTable?: (name: string) => string;
  backgroundColor: string;
};

const TableSet = observer(
  ({
    tablesSchema,
    columnDefs,
    dataKey,
    calculationId,
    useCaseKey,
    useCaseVersion = null,
    loadData,
    toCalculationResultTable,
    backgroundColor
  }: TableSetProps) => {
    const { tabsRoot, icon, tabs, multiView, tabRenderRoot } = useStyles({});
    const [selectedIndex, setSelectedIndex] = useState(0);

    const TabRender = (name: string) => (
      <div className={tabRenderRoot}>
        {name}
        <TableChart fontSize="small" className={icon} />
      </div>
    );

    const onTabsSelectionChanged = (args: OptionChangedEvent) => {
      if (args.name === "selectedIndex") {
        setSelectedIndex(args.value);
      }
    };

    return (
      <div className={tabsRoot}>
        <MultiView
          dataSource={tablesSchema}
          selectedIndex={selectedIndex}
          itemComponent={item => (
            <TableSetItem
              name={item.data.name}
              columnDefs={columnDefs}
              dataKey={dataKey}
              calculationId={calculationId}
              useCaseKey={useCaseKey}
              loadData={loadData}
              toCalculationResultTable={toCalculationResultTable}
              backgroundColor={backgroundColor}
              useCaseVersion={useCaseVersion}
            />
          )}
          swipeEnabled={false}
          animationEnabled={false}
          className={multiView}
        />
        <Tabs
          dataSource={tablesSchema?.map(schema => schema.name)}
          selectedIndex={selectedIndex}
          onOptionChanged={onTabsSelectionChanged}
          itemRender={TabRender}
          className={tabs}
        />
      </div>
    );
  }
);

export default TableSet;
