import { Button, Grid, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import { useSolutionKey } from "features/solution";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { createRoute } from "utils/url";
import NoUseCaseFound from "../NoUseCaseFound";
import CalculationsCard from "./components/CalculationsCard";
import DataCard from "./components/DataCard";
import PublishNewVersion from "./components/PublishNewVersion";
import UseCaseDetails from "./components/UseCaseDetails";
import { useQueryTopicsEssentials } from "./hooks/topicsHooks";

const useStyles = makeStyles(theme => ({
  grid: {
    backgroundColor: "#FFFFFF",
    minHeight: 600,
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
  },
  cancelButton: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8
    }
  },
  link: { textDecoration: "none" },
  gridItem: { padding: theme.spacing(4) },
  gridContainer: {
    padding: theme.spacing(4, 4, 0, 4)
  }
}));

type TopicsProps = {
  useCaseKey: string;
  useCaseVersion: string;
};

const Topics: React.FC<TopicsProps> = ({ useCaseKey, useCaseVersion }) => {
  const solutionKey = useSolutionKey();
  const {
    data: essentials,
    loading,
    error
  } = useQueryTopicsEssentials({
    solutionKey,
    useCaseKey,
    useCaseVersion
  });

  const jss = useStyles();
  if (loading) return <LinearProgress />;
  if (error) {
    return <ErrorBox apolloError={error} />;
  }
  if (!essentials?.solutions.solution?.useCases?.useCase) {
    return <NoUseCaseFound />;
  }
  const { detail } = essentials?.solutions.solution?.useCases?.useCase;
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item container className={jss.grid}>
        <Grid item xs={12}>
          <UseCaseDetails {...detail} />
        </Grid>
        <Grid container data-testid="topics" className={jss.gridContainer}>
          <DataCard useCaseKey={useCaseKey} useCaseVersion={useCaseVersion} />
          <CalculationsCard
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
          />
        </Grid>
        <Grid item md={12} className={jss.gridItem}>
          <RouterLink
          to={createRoute(`/solutions/${solutionKey}/use-cases`)}
            className={jss.link}
          >
            <Button className={jss.cancelButton} variant="contained">
              CANCEL
            </Button>
          </RouterLink>
          <PublishNewVersion
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Topics;
