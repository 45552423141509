import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
  capacitiesCounts: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    minWidth: 200,
    minHeight: 300
  },
  taken: {
    backgroundColor: theme.palette.primary.main,
    width: 99,
    height: "100%",
    zIndex: 1000,
    bottom: 0,
    left: 0,
    position: "absolute"
  },
  free: {
    display: "flex",
    alignItems: "flex-start",
    position: "absolute",
    height: "100%",
    width: 99,
    bottom: 0,
    left: 0
  },
  max: {
    height: "100%",
    backgroundColor: "#f7eed9",
    width: 99,
    position: "absolute",
    top: 0,
    left: 0
  },
  status: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    flex: 1,
    minHeight: 400,
    gridArea: "status"
  },
  pending: {
    display: "flex",
    alignItems: "flex-start",
    position: "absolute",
    height: "100%",
    width: 99,
    bottom: 0,
    left: 0
  },
  legends: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    gridArea: "legends"
  },
  onStandByLegend: { backgroundColor: "#f7eed9", border: "none" },
  grid: {
    display: "grid",
    gridTemplateColumns: "100px 120px 75px",
    gridTemplateRows: "auto",
    gridTemplateAreas: ` "header header header"
     "status minAndMax legends"
     ". negativePending ."
     `,
    gap: theme.spacing(2)
  },
  header: { gridArea: "header" }
}));
