import { LinearProgress } from "@mui/material";
import { useSolutionKey } from "features/solution";
import AddProject from "./AddProject";
import NoProjectFound from "./NoProjectFound";
import useQueryProjectById from "./hooks/useQueryProjectById";

type AddProjectProps = {
  projectId: string;
};

export default function EditProject({ projectId }: AddProjectProps) {
  const solutionKey = useSolutionKey();
  const { data: projectById, loading: isLoadingProject } = useQueryProjectById({
    solutionKey,
    projectId
  });

  const project = projectById?.solutions.solution?.projects?.project;
  if (isLoadingProject) return <LinearProgress />;
  if (!project) {
    return <NoProjectFound />;
  }
  return (
    <AddProject project={project} editProject={true} projectId={projectId} />
  );
}
