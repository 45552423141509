import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%"
  },
  tableSetWrapper: {
    flex: "1 1 0",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  marginLeft: {
    marginLeft: theme.spacing(3)
  },
  button: {
    textTransform: "capitalize",
    color: "#fff",
    alignSelf: "flex-end",
    margin: theme.spacing(1)
  },
  centered: {
    display: "flex",
    flex: "1 1 auto",
    alignItems: "center",
    justifyContent: "center"
  },
  leftBackgroundColor: {
    backgroundColor: theme.palette.primary.main
  },
  rightBackgroundColor: {
    backgroundColor: theme.palette.primary.dark
  }
}));
