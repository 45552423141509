import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SolutionPermission } from "features/access-management";
import * as React from "react";
import theme from "utils/theme";

const useStyles = makeStyles(() => ({
  listHeader: {
    background: "aliceblue"
  },
  dialogContent: { padding: 0 },
  item: { padding: theme.spacing(1, 3) }
}));

export default function MemberPermissions({
  permissions,
  firstName,
  lastName,
  solutionName
}: {
  permissions: (SolutionPermission | null)[] | null;
  firstName: string | null;
  lastName: string | null;
  solutionName: string;
}) {
  const classes = useStyles();

  const [isPermissionsDialogOpen, setIsPermissionDialogOpen] =
    React.useState(false);

  return (
    <>
      <Tooltip
        title={
          <>
            View permissions of
            <b>{` ${firstName} ${lastName}`}</b> for solution{" "}
            <b>{` ${solutionName}`}</b>
          </>
        }
      >
        <IconButton
          data-testid="show-member-permissions"
          onClick={() => {
            setIsPermissionDialogOpen(true);
          }}
          size="large"
        >
          <LockOpenIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isPermissionsDialogOpen}
        onClose={() => setIsPermissionDialogOpen(false)}
        BackdropProps={{
          style: {
            backgroundColor: "rgb(0,0,0,0.2)"
          }
        }}
        PaperProps={{
          style: {
            boxShadow: "none"
          }
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <List
            dense
            subheader={
              <ListSubheader className={classes.listHeader}>
                {firstName} {lastName}'s permissions
              </ListSubheader>
            }
          >
            {permissions?.map((permission: string | null) => (
              <ListItem key={permission} className={classes.item}>
                <ListItemIcon>
                  <LockOpenIcon />
                </ListItemIcon>
                <ListItemText primary={permission} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
