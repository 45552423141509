import { useMutation } from "@apollo/client";
import PublishIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Link from "components/base/Link";
import BasicCard from "components/card/BasicCard";
import { useSolutionKey } from "features/solution";
import { mutationDeleteUseCase } from "./hooks/mutationDeleteUseCase";
import {
  DeleteUseCase,
  DeleteUseCaseVariables
} from "./hooks/schema/DeleteUseCase";
import { UseCaseType, queryUseCaseList } from "./hooks/useQueryUseCaseList";

export const useStyles = makeStyles(theme => ({
  deleteIcon: {
    display: "none",
    pointerEvents: "none",
    padding: 6
  },
  publishIcon: {
    padding: 6
  },
  iconColor: {
    color: theme.palette.primary.main
  }
}));

export default function UseCaseCard({
  useCaseKey,
  tags,
  name,
  image,
  description,
  version
}: UseCaseType) {
  const solutionKey = useSolutionKey();
  const { deleteIcon, publishIcon, iconColor } = useStyles({});
  const [lazyUseDeleteUseCase, { loading, called }] = useMutation<
    DeleteUseCase,
    DeleteUseCaseVariables
  >(mutationDeleteUseCase, {
    refetchQueries: [{ query: queryUseCaseList, variables: { solutionKey } }]
  });
  const invalidated = loading || called;

  return (
    <Grid item data-test="use-case-card">
      <BasicCard
        disabled={invalidated}
        name={name}
        icon={image}
        tags={tags}
        linkTo={`./${useCaseKey}/${version}`}
        description={description}
      >
        <div>
          <IconButton
            data-test="delete-use-case"
            className={deleteIcon}
            disabled={invalidated}
            onClick={async event => {
              event.stopPropagation();
              event.preventDefault();
              await lazyUseDeleteUseCase({
                variables: { solutionKey, useCaseKey }
              });
            }}
            size="large"
          >
            <DeleteIcon className={iconColor} />
          </IconButton>
          <Link
            to={`/solutions/${solutionKey}/create-use-case-version/${useCaseKey}`}
          >
            <Tooltip
              placement="left"
              title={<Typography> Publish new version </Typography>}
            >
              <IconButton className={publishIcon} size="large">
                <PublishIcon className={iconColor} />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      </BasicCard>
    </Grid>
  );
}
