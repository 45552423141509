import { Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { InfoOutlined } from "@mui/icons-material";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  info: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1)
  }
}));

type InfoTooltipProps = {
  title: NonNullable<React.ReactNode>;
  typography?: boolean;
  children: React.ReactNode;
};

const InfoTooltip = ({
  title,
  children,
  typography = false
}: InfoTooltipProps) => {
  const css = useStyles({});
  return (
    <div className={css.root}>
      {children}
      <Tooltip title={typography ? <Typography>{title}</Typography> : title}>
        <InfoOutlined className={css.info} fontSize="small" />
      </Tooltip>
    </div>
  );
};

export default InfoTooltip;
