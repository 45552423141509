import { gql, useQuery } from "@apollo/client";
import {
  QueryCalculationsInfo,
  QueryCalculationsInfoVariables
} from "./schema/QueryCalculationsInfo";
import {
  QueryLatestVersion,
  QueryLatestVersionVariables
} from "./schema/QueryLatestVersion";
import {
  QueryTopicsEssentials,
  QueryTopicsEssentialsVariables,
  QueryTopicsEssentials_solutions_solution_useCases_useCase_detail
} from "./schema/QueryTopicsEssentials";
import {
  QueryUploadDataInfo,
  QueryUploadDataInfoVariables
} from "./schema/QueryUploadDataInfo";

export type UseCaseDetail =
  QueryTopicsEssentials_solutions_solution_useCases_useCase_detail;

const queryTopicsEssentials = gql`
  query QueryTopicsEssentials(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            detail {
              version
              tags
              name
              description
              image
              useCaseKey: key
            }
          }
        }
      }
    }
  }
`;

/* The information used in this query should be limited to what is essentially
 *  needed to display a use case topic.  */
export const useQueryTopicsEssentials = (
  variables: QueryTopicsEssentialsVariables
) =>
  useQuery<QueryTopicsEssentials, QueryTopicsEssentialsVariables>(
    queryTopicsEssentials,
    { variables }
  );

const queryUploadDataInfo = gql`
  query QueryUploadDataInfo(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            data {
              address
            }
          }
        }
      }
    }
  }
`;

export const useQueryUploadDataInfo = (
  variables: QueryUploadDataInfoVariables
) => {
  return useQuery<QueryUploadDataInfo, QueryUploadDataInfoVariables>(
    queryUploadDataInfo,
    { variables }
  );
};

const queryCalculationsInfo = gql`
  query QueryCalculationsInfo(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            myCalculations: calculations(scheduler: "me") {
              id
            }
            allCalculations: calculations {
              id
            }
          }
        }
      }
    }
  }
`;

export const useQueryCalculationsInfo = (
  variables: QueryCalculationsInfoVariables
) =>
  useQuery<QueryCalculationsInfo, QueryCalculationsInfoVariables>(
    queryCalculationsInfo,
    { variables }
  );

const queryLatestVersion = gql`
  query QueryLatestVersion($solutionKey: Key!, $useCaseKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          latestUseCase: useCase(useCaseKey: $useCaseKey) {
            detail {
              version
            }
          }
        }
      }
    }
  }
`;

export const useQueryLatestVersion = (variables: QueryLatestVersionVariables) =>
  useQuery<QueryLatestVersion, QueryLatestVersionVariables>(
    queryLatestVersion,
    { variables }
  );
