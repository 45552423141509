import { gql, useQuery } from "@apollo/client";
import { Permissions } from "./schema/Permissions";

export const solutionMemberPermissionsQuery = gql`
  query Permissions($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        access {
          me {
            name
            permissions
          }
        }
      }
    }
  }
`;
export default function usePermissionsData(solutionKey: string) {
  return useQuery<Permissions>(solutionMemberPermissionsQuery, {
    variables: { solutionKey },
    fetchPolicy:
      /** Permissions will be loaded once when app is loaded.
       *  User has to reload the application - for example, by pressing the F5 key - to retrieve updated permissions.
       * */
      "cache-first"
  });
}
