import { ApolloError, gql, useApolloClient, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { JobsCount, JobsCountVariables } from "./schema/JobsCount";
import {
  QueryUseCaseJobs,
  QueryUseCaseJobsVariables
} from "./schema/QueryUseCaseJobs";

export const jobsQuery = gql`
  query QueryUseCaseJobs(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $scheduler: String
    $skipUntilId: ID
    $take: Int
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            calculations(
              scheduler: $scheduler
              skipUntilId: $skipUntilId
              take: $take
            ) {
              id
              detail {
                completed
                created
                started
                state
            username {
              firstName
              lastName
              name
            }
              }
              inputData {
                detail {
                  metaData {
                    displayName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const jobsCountQuery = gql`
  query JobsCount(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $scheduler: String
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            calculationsCount(scheduler: $scheduler)
          }
        }
      }
    }
  }
`;

export const useQueryUseCaseJobsCount = (variables: JobsCountVariables) =>
  useQuery<JobsCount, JobsCountVariables>(jobsCountQuery, {
    variables
  });

export enum JobState {
  Completed = "Completed",
  Failed = "Failed",
  InProgress = "InProgress",
  Scheduled = "Scheduled"
}

interface User {
  firstName: string | null;
  lastName: string | null;
  name: string;
}
export interface RowData {
  id: string;
  completed: Date | null;
  created: Date | null;
  started: Date | null;
  state: JobState;
  username: User;
  displayName: string | undefined;
}

export const useQueryUseCaseCalculations = (
  solutionKey: string,
  useCaseKey: string,
  useCaseVersion?: string | null,
  scheduler?: string | null,
  skipUntilId?: string | null,
  take?: number | null
) => {
  const client = useApolloClient();

  const [loading, setLoading] = useState<boolean>(true);
  const [allJobs, setAllJobs] = useState<RowData[] | null>([]);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [errors, setErrors] = useState<ApolloError[]>([]);
  const loadUseCaseJobs = useCallback(
    async (variables: QueryUseCaseJobsVariables) => {
      let { skipUntilId, take, ...rest } = variables;
      try {
        setLoading(true);
        let loaded = 0;
        do {
          const { errors, data } = await client.query<
            QueryUseCaseJobs,
            QueryUseCaseJobsVariables
          >({
            query: jobsQuery,
            variables: { skipUntilId, take, ...rest },
            errorPolicy: "all"
          });
          if (errors) {
            setErrors(e => [...e, new ApolloError({ graphQLErrors: errors })]);
          }
          const useCase = data.solutions.solution?.useCases?.useCase;
          if (!useCase) {
            setAllJobs(null);
          }
          const calculations = useCase?.calculations?.map(
            ({
              id,
              detail: { completed, created, started, state, username },
              inputData
            }) => ({
              id,
              completed: completed ? new Date(completed) : null,
              created: created ? new Date(created) : null,
              started: started ? new Date(started) : null,
              state,
              username,
              displayName:
                inputData.detail && inputData.detail.metaData.displayName
                  ? inputData.detail.metaData.displayName
                  : undefined
            })
          );

          loaded = calculations?.length || 0;
          if (Array.isArray(calculations) && calculations.length > 0) {
            skipUntilId = calculations.slice(-1)[0]?.id;
            setAllJobs(d =>
              d === null ? [...calculations] : [...d, ...calculations]
            );
            setProgressValue(value => value + calculations.length);
          }
        } while (loaded === take);

        setLoading(false);
      } catch (error) {
        setErrors(e => [error as ApolloError, ...e]);
        setLoading(false);
      }
    },
    [client]
  );
  useEffect(() => {
    const loadJobs = async () => {
      await loadUseCaseJobs({
        solutionKey,
        useCaseKey,
        useCaseVersion,
        scheduler,
        skipUntilId,
        take
      });
    };
    loadJobs();
  }, [
    loadUseCaseJobs,
    solutionKey,
    useCaseKey,
    useCaseVersion,
    scheduler,
    take,
    skipUntilId
  ]);

  const reloadJobs = useCallback(async () => {
    if (!useCaseKey) return;
    setAllJobs([]);
    setProgressValue(0);

    await loadUseCaseJobs({
      solutionKey,
      useCaseKey,
      useCaseVersion,
      scheduler,
      skipUntilId: null,
      take
    });
  }, [
    solutionKey,
    loadUseCaseJobs,
    useCaseKey,
    useCaseVersion,
    scheduler,
    take
  ]);

  // return state
  return {
    allJobs,
    loading,
    progressValue,
    reloadJobs,
    errors
  };
};
