import { TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 60,
    margin: theme.spacing(0, 1),
    "& input.Mui-disabled": {
      color: theme.palette.common.black
    }
  }
}));
interface EditFieldProps {
  value: number;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
  min: string;
  max?: string;
}

export default function EditField({
  value,
  onChange,
  disabled,
  min,
  max
}: EditFieldProps) {
  const classes = useStyles({});
  return (
    <TextField
      value={value}
      onChange={onChange}
      margin="none"
      className={classes.root}
      disabled={disabled}
      inputProps={{
        min,
        max,
        inputMode: "numeric",
        pattern: "[0-9]*",
        style: { textAlign: "center" }
      }}
      type="number"
      variant="standard"
    />
  );
}
