import React from "react";

export default function FullSize(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%"
      }}
      {...props}
    >
      {props.children}
    </div>
  );
}
