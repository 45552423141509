import {
  ClickAwayListener,
  Fade,
  LinearProgress,
  Paper,
  Popper
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { HtmlEditor } from "devextreme-react";
import "devextreme/ui/html_editor/converters/markdown";
import { Suspense, useEffect, useState } from "react";
import { createRoute } from "utils/url";

const useStyles = makeStyles(theme => ({
  root: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center"
    }
  },
  paper: {
    zIndex: 1000,
    minHeight: "40vh",
    maxHeight: "85vh",
    overflow: "auto",
    padding: "35px",
    backgroundColor: "#ebf0f5"
  },
  popper: {
    top: 68,
    zIndex: 1000,
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      top: 66
    },
    inset: `10px auto auto 0px !important`
  },
  changeLogPreview: {
    border: `none !important`
  }
}));

export default function EnvironmentDetails({
  environmentCaption,
  title,
  open,
  closePopper,
  anchorEl,
  children
}: {
  environmentCaption?: string;
  title: string;
  open: boolean;
  closePopper: () => void;
  anchorEl: HTMLElement | null;
  children(title: string, ec?: string): JSX.Element;
}) {
  const { paper, popper, changeLogPreview } = useStyles({});
  const [changelogMarkdown, setChangelogMarkdown] = useState<null | string>(
    null
  );

  const handleClose = () => {
    closePopper();
    setChangelogMarkdown(null);
  };

  useEffect(() => {
    if (open && !changelogMarkdown) {
      fetch(createRoute("/CHANGELOG.md"))
        .then(r => (r.ok ? r.text() : "# Unable to load changelog"))
        .then(changelogMarkdown => setChangelogMarkdown(changelogMarkdown));
    }
  }, [open, changelogMarkdown]);

  return (
    <>
      {children(title, environmentCaption)}
      <Popper open={open} anchorEl={anchorEl} transition className={popper}>
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps}>
                <Paper className={paper}>
                  <Suspense fallback={<LinearProgress />}>
                    {!changelogMarkdown ? (
                      <LinearProgress />
                    ) : (
                      <HtmlEditor
                        value={changelogMarkdown}
                        readOnly={true}
                        valueType="markdown"
                        className={changeLogPreview}
                      />
                    )}
                  </Suspense>
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </>
  );
}
