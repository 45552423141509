import _ from "lodash";
import { DeepOmit } from "utils/typescript";
import { Members_solutions_solution_access_users } from "./hooks/schema/Members";

function notNull<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const getAllMemberGroups = (
  members: DeepOmit<Members_solutions_solution_access_users, "__typename">[]
): string[] => {
  const memberGroups = _.flattenDeep(members.map(member => member.groups)).map(
    group => group
  );
  const uniqGroups = _.uniq(memberGroups);
  return _.sortBy(uniqGroups).filter(notNull);
};
