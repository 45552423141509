import { useRequestUserPublicApiPermissions } from "features/public-api";
import { PublicApiPermission } from ".";
import RestrictedRealm, { BasicRestrictedRealmProps } from "./RestrictedRealm";

export default function RestrictedPublicApiRealm({
  requiredPermissions,
  silent = false,
  children,
  fallback
}: BasicRestrictedRealmProps<PublicApiPermission>) {
  const {
    data: { accessGranted, missingPermissions },
    loading,
    error
  } = useRequestUserPublicApiPermissions(requiredPermissions);
  return (
    <RestrictedRealm<PublicApiPermission>
      requiredPermissions={requiredPermissions}
      accessGranted={accessGranted}
      missingPermissions={missingPermissions}
      children={children}
      error={error}
      loading={loading}
      fallback={fallback}
      silent={silent}
      errorTitle="Unable to access public api permissions"
    />
  );
}
